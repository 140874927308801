export function getQueryStringParams(query) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
}

export function handleGoBack(history) {
  history.goBack();
}

export function goTo(link) {
  this.setState({ toggledNav: false });
  this.props.history.push(link);
}

export function getUploadedFiles(version) {
  const uploadedFilesTemp = Object.entries(version).filter((f) =>
    f[0].includes('_file'),
  );
  let uploadedFiles = [];

  uploadedFiles = uploadedFilesTemp.map((u) => ({
    file: u[0],
    fileName: u[1].split('|')[1],
    timestamp: u[1].split('|')[0],
  }));

  return uploadedFiles;
}

export function configProjectsTableHeaders() {
  return {
    tableHeaders: [
      {
        label: 'Name',
        value: 'project_name',
      },
      {
        label: 'Current Version Status',
        value: 'last_submission_status',
      },
      {
        label: 'Last Updated',
        value: 'update_time',
      },
    ],
  };
}

export function configTransporter() {
  return {
    transporterForm: {
      fields: [
        {
          label: 'Team ID',
          name: 'team_id',
          placeholder: 'Enter Team ID...',
          type: 'text',
          value: '',
          help: '',
          os: 'ios',
        },
        {
          label: 'Bundle Id',
          name: 'bundle_id',
          placeholder: 'Enter Bundle Id...',
          type: 'text',
          value: '',
          help: '',
          os: 'ios',
        },
        {
          label: 'App SKU',
          name: 'app_sku',
          placeholder: 'Enter App SKU...',
          type: 'text',
          value: '',
          help: '',
          os: 'ios',
        },
        {
          label: 'Package Name',
          name: 'package_name',
          placeholder: 'Enter Package Name...',
          type: 'text',
          value: '',
          help: '',
          os: 'android',
        },
      ],
      commands: [
        { type: 'btn-primary', label: 'Validate' },
        { type: 'btn-success', label: 'Submit App' },
      ],
    },
  };
}

export function configShowProject() {
  return {
    formNavOptions: [
      {
        link: 'https://d225y4f9lneqkq.cloudfront.net/ios.zip',
        image: '/ios.png',
        title: 'iOS',
      },
      {
        link: 'https://d225y4f9lneqkq.cloudfront.net/googleplay.zip',
        image: '/android.png',
        title: 'Google Play',
      },
      {
        link: 'https://d225y4f9lneqkq.cloudfront.net/amazon.zip',
        image: '/Amazon.png',
        title: 'Amazon',
      },
      {
        link: 'https://d225y4f9lneqkq.cloudfront.net/oculus.zip',
        image: '/Oculus.png',
        title: 'Oculus',
      },
      {
        link: 'https://d225y4f9lneqkq.cloudfront.net/htc.zip',
        image: '/HTC.png',
        title: 'HTC Viveport',
      },
      {
        link: 'https://d225y4f9lneqkq.cloudfront.net/roku.zip',
        image: '/Roku.png',
        title: 'Roku',
      },
      {
        link: 'https://d225y4f9lneqkq.cloudfront.net/samsung.zip',
        image: '/Samsung.png',
        title: 'Samsung',
      },
      {
        link: 'https://d225y4f9lneqkq.cloudfront.net/apple_ebooks.zip',
        image: '/AppleEBooks.png',
        title: 'Apple eBooks',
      },
    ],
    assetDropdownOptions: [
      { label: 'Item Required', value: 'ITEM_REQUIRED' },
      { label: 'Uploaded', value: 'UPLOADED' },
      { label: 'Pending', value: 'PENDING' },
      { label: 'Processed', value: 'PROCESSED' },
      { label: 'Rejected', value: 'REJECTED' },
    ],
    overflowOptionsAdmin: [
      { label: 'Edit Project', value: 'Edit Project' },
      { label: 'Archive Project', value: 'Archive Project' },
      { label: 'Delete Project', value: 'Delete Project' },
      { label: 'Go To S3 Bucket', value: 'Go To S3 Bucket' },
      { label: 'View As User', value: 'View As User' },
    ],
    overflowOptionsUser: [
      { label: 'Edit Project', value: 'Edit Project' },
      { label: 'Archive Project', value: 'Archive Project' },
      { label: 'Delete Project', value: 'Delete Project' },
      { label: 'Go To S3 Bucket', value: 'Go To S3 Bucket' },
      { label: 'View As Admin', value: 'View As Admin' },
    ],
    overflowOptionsAdmin1: [
      { label: 'Edit Project', value: 'Edit Project' },
      { label: 'Restore Project', value: 'Restore Project' },
      { label: 'Delete Project', value: 'Delete Project' },
      { label: 'Go To S3 Bucket', value: 'Go To S3 Bucket' },
      { label: 'View As User', value: 'View As User' },
    ],
    overflowOptionsUser1: [
      { label: 'Edit Project', value: 'Edit Project' },
      { label: 'Restore Project', value: 'Restore Project' },
      { label: 'Delete Project', value: 'Delete Project' },
      { label: 'Go To S3 Bucket', value: 'Go To S3 Bucket' },
      { label: 'View As Admin', value: 'View As Admin' },
    ],
    overflowOptionsVersion: [
      { label: 'Edit Version', value: 'Edit Version' },
      { label: 'Download Assets', value: 'Download Assets' },
      { label: 'Transporter Tool', value: 'Transporter Tool' },
    ],
    superButtonOptions: [
      {
        label: 'Items Required',
        value: 'ITEMS_REQUIRED',
        action: 'supersubmit',
      },
      {
        label: 'Ready to Submit',
        value: 'READY_TO_SUBMIT',
        action: 'supersubmit',
      },
      {
        label: 'Pending',
        value: 'PENDING',
        action: 'supersubmit',
      },
      {
        label: 'Submitted',
        value: 'SUBMITTED',
        action: 'supersubmit',
      },
      {
        label: 'Rejected',
        value: 'REJECTED',
        action: 'supersubmit',
      },
    ],
    inputAttributeOptions: [
      { label: 'Item Required', value: 'ITEM_REQUIRED' },
      { label: 'Uploaded', value: 'UPLOADED' },
      { label: 'Pending', value: 'PENDING' },
      { label: 'Processed', value: 'PROCESSED' },
      { label: 'Rejected', value: 'REJECTED' },
    ],
    superSubmitOptions: [
      { label: 'Items Required', value: 'ITEMS_REQUIRED' },
      { label: 'Ready to Submit', value: 'READY_TO_SUBMIT' },
      { label: 'Pending', value: 'PENDING' },
      { label: 'Submitted', value: 'SUBMITTED' },
      { label: 'Rejected', value: 'REJECTED' },
    ],
  };
}
