import { API } from 'aws-amplify';
import strings from '../config/strings';
const instance = API;

export const updateProjectApi = (
  projectId,
  attributeName,
  attributeValue,
  conditionAttribute,
  conditionValue,
) => {
  const body = {};
  body[attributeName] = attributeValue;
  if (conditionAttribute && conditionValue) {
    body[conditionAttribute] = conditionValue;
  }
  return instance.put('Alps', `/project/${projectId}`, { body }, {});
};

export const getPresignedRequest = (s3Key, projectId) => {
  return instance.get(
    'Alps',
    `/presignedUrl?s3Key=${encodeURIComponent(s3Key)}&projectId=${projectId}`,
    {},
  );
};

export const getDownloadUrl = (s3Key, projectId) => {
  return instance.get(
    'Alps',
    `/downloadUrl?s3Key=${encodeURIComponent(s3Key)}&projectId=${projectId}`,
    {},
  );
};

export const getAssetFileNames = (
  projectId,
  submissionLabel,
  infoId,
  projectType,
) => {
  if (infoId.includes('test')) {
    let testFolder = strings.testServiceName[projectType];
    return instance.get(
      'Alps',
      `/project/${projectId}/assets/${testFolder}/${submissionLabel}`,
      {},
    );
  } else {
    return instance.get(
      'Alps',
      `/project/${projectId}/assets/${submissionLabel}`,
      {},
    );
  }
};

export const updateAssetStatusApi = (
  projectId,
  submissionId,
  assetPath,
  assetStatus,
  fileName,
  date,
) => {
  return instance.put(
    'Alps',
    `/project/${projectId}/submission/${submissionId}/asset/${assetPath}/assetStatus/${assetStatus.value}`,
    {
      body: {
        fileName: fileName,
        uploadedAt: date,
      },
    },
  );
};

/* export const postChangeNotification = (
  projectId,
  projectName,
  submissionLabel,
  changes,
) => {
  return instance.post(
    'Alps',
    `/changes`,
    {
      body: {
        project_id: projectId,
        project_name: projectName,
        submission_label: submissionLabel,
        changes,
      },
    },
    {},
  );
}; */

/* export const unsubscribe = token => {
  return instance.del('Alps', `/notification/${token}`);
}; */
