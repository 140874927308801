import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
class Logout extends Component {
  async componentDidMount() {
    try {
      await Auth.signOut({ global: true });
      await localStorage.clear();
      window.location.assign('/landing');
    } catch (e) {
      // signout failed
      console.log(e);
    }
  }

  render() {
    return <div></div>;
  }
}

export default Logout;
