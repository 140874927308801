import {
  CREATE_PROJECT,
  GET_PROJECTS,
  GET_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  CREATE_PROJECT_VERSION,
  UPDATE_PROJECT_VERSION_STATUS,
  UPDATE_PROJECT_ASSET_STATUS,
  GET_PROJECT_NOTIFICATIONS,
  ADD_USER_TO_PROJECT_NOTIFICATIONS,
  UPDATE_USER_PROJECT_NOTIFICATIONS,
  UPDATE_PROJECT_VERSION_NOTES,
  REMOVE_USER_FROM_PROJECT_NOTIFICATIONS,
  GET_VERSION_LOG,
  SET_ALERT,
} from './types';

import strings from '../config/strings';
import { API } from 'aws-amplify';
const instance = API;

function htmlToText(html) {
  //remove code brakes and tabs
  html = html.replace(/\n/g, '');
  html = html.replace(/\t/g, '');

  //keep html brakes and tabs
  html = html.replace(/<\/td>/g, '\t');
  html = html.replace(/<\/table>/g, '\n');
  html = html.replace(/<\/tr>/g, '\n');
  html = html.replace(/<\/ul>/g, '\n');
  html = html.replace(/<\/ol>/g, '\n');
  html = html.replace(/<\/li>/g, '\n');
  html = html.replace(/<li>/g, ' * ');
  html = html.replace(/<\/p>/g, '\n');
  html = html.replace(/<\/div>/g, '\n');
  html = html.replace(/<div>/g, '\n');
  html = html.replace(/<\/h>/g, '\n');
  html = html.replace(/<br>/g, '\n');
  html = html.replace(/<br( )*\/>/g, '\n');

  //parse html into text
  var dom = new DOMParser().parseFromString(
    '<!doctype html><body>' + html,
    'text/html',
  );
  return dom.body.textContent;
}

export const getProjects = () => async (dispatch) => {
  let res = {};
  try {
    res = await instance.get('Alps', '/project', {});

    dispatch({
      type: GET_PROJECTS,
      payload: res,
    });
  } catch (error) {
    if (!error.response) {
      window.location.assign('/logout');
    }
    if (error.response.data.message) {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'error',
          message: error.response.data.message,
          icon: 'ion-alert-circled',
        },
      });
    } else {
      alert(error + ' (Refresh page or email us with screenshot).');
      window.location.assign('/logout');
    }
  }
};

export const getProject = (id) => async (dispatch) => {
  let res = {};

  try {
    res = await instance.get('Alps', `/project/${id}`, {});

    dispatch({
      type: GET_PROJECT,
      payload: res,
    });
  } catch (error) {
    alert(error + ' (Refresh page or email us with screenshot).');
    window.location.assign('/logout');
  }
};

export const createProject = (project) => async (dispatch) => {
  let res = {};
  try {
    res = await instance.post('Alps', '/project', {
      body: {
        project_name: project.fields[0].value,
        project_type: project.fields[1].value,
      },
    });
    dispatch({
      type: CREATE_PROJECT,
      payload: res,
    });
  } catch (error) {
    if (error.response.data.message) {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'error',
          message: error.response.data.message,
          icon: 'ion-alert-circled',
        },
      });
    } else {
      alert(error);
    }
  }
};

export const deleteProject = (id) => async (dispatch) => {
  let res = {};
  try {
    res = await instance.del('Alps', `/project/${id}`, {});
    dispatch({
      type: DELETE_PROJECT,
      payload: res,
    });
    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: 'Deleted project.',
          icon: 'ion-checkmark-circled',
        },
      });
    }, 500);
  } catch (error) {
    if (error.response.data.message) {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'error',
          message: error.response.data.message,
          icon: 'ion-alert-circled',
        },
      });
    } else {
      alert(error);
    }
  }
};

export const createProjectVersion = (object, project, versionType) => async (
  dispatch,
) => {
  const versionLabel =
    versionType === 'update' || versionType === 'new'
      ? object.fields[0].value
      : `${object.fields[0].value} - ${object.fields[1].value}`;

  let res = {};

  try {
    res = await instance.post(
      'Alps',
      `/project/${project.project_id}/submission`,
      {
        body: {
          submission_type: versionType,
          submission_label: versionLabel,
        },
      },
      {},
    );
    dispatch({
      type: CREATE_PROJECT_VERSION,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const updateProjectVersionStatus = (
  projectProps,
  selectedVersion,
  versionProps,
) => async (dispatch) => {
  //console.log('versionProps', versionProps);
  //console.log('versionProps Key', Object.keys(versionProps)[0]);
  //console.log('versionProps Value', Object.values(versionProps)[0]);
  let res = {};

  try {
    res = await instance.put(
      'Alps',
      `/project/${projectProps.project_id}/submission/${selectedVersion.info_id}`,
      { body: versionProps },
      {},
    );
    dispatch({
      type: UPDATE_PROJECT_VERSION_STATUS,
      payload: res,
    });
    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `Updated ${projectProps.project_name}.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 500);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const getVersionLog = (projectId, submissionId) => async (dispatch) => {
  let res = {};
  try {
    res = await instance.get(
      'Alps',
      `/project/${projectId}/submission/${submissionId}/logs`,
      {},
    );
    dispatch({
      type: GET_VERSION_LOG,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const updateProject = (project, projectProps) => async (dispatch) => {
  console.log('project updateProject', project);
  try {
    let body = {};
    if (typeof project === 'object') {
      body = project;
    } else {
      body[project.fields[0].name] = project.fields[0].value;
    }

    if ('project_type' && projectProps.project_type) {
      body['project_type'] = projectProps.project_type;
    }
    const res = await instance.put(
      'Alps',
      `/project/${projectProps.project_id}`,
      { body },
      {},
    );

    dispatch({
      type: UPDATE_PROJECT,
      payload: res,
    });

    setTimeout(() => {
      let label = '';
      if (project.fields) {
        if (project.fields[0].name === 'project_name') {
          label = 'project name';
        } else if (project.fields[0].name === 'status') {
          project.fields[0].value === 'ACTIVE'
            ? (label = 'project status to Active')
            : (label = 'project status to Archived');
        } else {
          console.log('else');
          label = project.fields[0].name;
        }
      } else {
        if (project.project_type) {
          label = 'App Info';
        }
      }

      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `Updated ${label}.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response
          ? error.response.data.message
          : 'An error has occured.',
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const updateProjectVersionNotes = (
  project,
  selectedVersion,
  notes,
) => async (dispatch) => {
  const dateStr = new Date().toISOString();

  // a separate folder for test builds
  let testFolder = null;
  if (selectedVersion.info_id.startsWith('test')) {
    testFolder = strings.testServiceName[project.project_type];
  }

  const projectId = project.project_id;
  const projectFolder = project.project_folder;
  const submissionLabel = selectedVersion.submission_label;
  const note = notes;

  const s3Key = testFolder
    ? `${projectFolder}/${testFolder}/${submissionLabel}/note/${dateStr}.txt`
    : `${projectFolder}/${submissionLabel}/note/${dateStr}.txt`;

  let textFileContents = htmlToText(note);
  let res = {};

  try {
    res = await instance.post(
      'Alps',
      `/project/${projectId}/submission/${selectedVersion.info_id}/s3Object`,
      {
        body: {
          path: s3Key,
          text: textFileContents,
          html: note,
        },
      },
      {},
    );

    dispatch({
      type: UPDATE_PROJECT_VERSION_NOTES,
      payload: res,
    });
    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message:
            'Note successfully uploaded. Check Activity Tab for history of notes.',
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const updateAssetStatus = (
  projectId,
  submissionId,
  assetPath,
  assetStatus,
  projectName,
  submissionLabel,
) => async (dispatch) => {
  //console.log('updateAssetStatus assetPath', projectName);
  let res = {};

  try {
    res = await instance.put(
      'Alps',
      `/project/${projectId}/submission/${submissionId}/asset/${assetPath}/assetStatus/${assetStatus.value}`,
      {
        body: {},
      },
    );

    dispatch({
      type: UPDATE_PROJECT_ASSET_STATUS,
      payload: res,
    });
    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `${assetPath.substr(3)} status: ${
            assetStatus.label ? assetStatus.label : 'Requested Q/A Upload'
          }.`,
          icon: 'ion-checkmark-circled',
        },
      });
      //console.log('assetPath', assetPath, assetStatus);
      /* dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `${assetPath.substr(3)} status: ${
            assetStatus.label ? assetStatus.label : 'Requested Q/A Upload'
          }.`,
          icon: 'ion-checkmark-circled',
        },
      }); */
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response
          ? error.response.data.message
          : `Error ${error}`,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const getProjectNotifications = (id) => async (dispatch) => {
  let res = {};
  try {
    res = await instance.get('Alps', `/project/${id}/notification`, {});

    dispatch({
      type: GET_PROJECT_NOTIFICATIONS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const addUserToProjectNotifications = (
  projectId,
  subscriptionChanges,
) => async (dispatch) => {
  let res = {};
  try {
    res = await instance.post(
      'Alps',
      `/project/${projectId}/notification`,
      {
        body: {
          email: subscriptionChanges.email,
          first_name: subscriptionChanges.first_name,
          last_name: subscriptionChanges.last_name,
          subscription_type: subscriptionChanges.subscription_type,
        },
      },
      {},
    );

    dispatch({
      type: ADD_USER_TO_PROJECT_NOTIFICATIONS,
      payload: res,
    });
    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `Added ${subscriptionChanges.email} to notifications.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'error',
          message: error.response
            ? error.response.data.message
            : 'Oops. Error hit.',
          icon: 'ion-alert-circled',
        },
      });
    }, 1000);
  }
};

export const updateUserProjectNotifications = (
  projectId,
  email,
  subscriptionChanges,
) => async (dispatch) => {
  let res = {};

  try {
    res = await instance.put(
      'Alps',
      `/project/${projectId}/notification/${email}`,
      { body: subscriptionChanges },
      {},
    );

    dispatch({
      type: UPDATE_USER_PROJECT_NOTIFICATIONS,
      payload: res,
    });
    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `Updated notifications for ${email}.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const removeUserFromProjectNotifications = (projectId, email) => async (
  dispatch,
) => {
  let res = {};

  try {
    res = await instance.del(
      'Alps',
      `/project/${projectId}/notification/${email}`,
      {},
    );

    dispatch({
      type: REMOVE_USER_FROM_PROJECT_NOTIFICATIONS,
      payload: res,
    });
    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `Removed ${email} from project.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};
