import LocalizedStrings from 'react-localization';

// react-localization defaults to the first language entry. We should store language in state, read from somewhere, on load.
const strings = new LocalizedStrings({
  en: {
    common: {
      title: 'ALPS Dashboard',
      loading: 'Loading',
      error: 'Error {0}',
    },
    navigation: {
      projects: 'Projects',
    },
    createProjectPage: {
      createProject: 'Create Project',
      projectName: 'Project Name',
      platform: 'Platform',
    },
    projectSubmissionPage: {
      versionPlaceholder: 'Select another version',
    },
    platforms: {
      ios: 'iOS',
      android: 'Google Play',
    },
    testServiceName: {
      ios: 'TestFlight',
      android: 'Testings',
    },
    submissionStatuses: {
      ITEMS_REQUIRED: 'Items Required',
      READY_TO_SUBMIT: 'Ready to Submit',
      PENDING: 'Pending',
      SUBMITTED: 'Submitted',
      REJECTED: 'Rejected',
      UPLOADED: 'Uploaded',
      PROCESSED: 'Processed',
    },
    requirements: {
      ALWAYS: 'always',
      OPTIONAL: 'optional',
    },
  },
});

export default strings;
