import { UPLOAD_FILE, UPLOAD_RESOURCE } from '../actions/types';

const initialState = {
  file: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        file: { file: action.payload[1] },
      };
    case UPLOAD_RESOURCE:
      return {
        ...state,
        file: action.payload,
      };
    default:
      return state;
  }
}
