import React, { Component } from 'react';

class Login extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.location.assign('/projects');
    }, 500);
  }

  render() {
    return <div></div>;
  }
}

export default Login;
