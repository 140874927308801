import { Auth } from 'aws-amplify';
// John Grant's Version
const cognito = {
  authURL: process.env.REACT_APP_AUTH_BASE_PATH,
  region: process.env.REACT_APP_COGNITO_REGION,
  domainPrefix: process.env.REACT_APP_COGNITO_DOMAIN_PREFIX,
  appClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  idp_identifier: process.env.REACT_APP_COGNITO_IDP_IDENTIFIER,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  authDomain: process.env.REACT_APP_AUTH_BASE_PATH,
};
const oauth = {
  // Domain name
  domain: cognito.authDomain,

  // Authorized scopes
  scope: ['openid', 'profile', 'email'],

  // Callback URL
  redirectSignIn: `${window.location.origin}/login`,

  // Sign out URL
  redirectSignOut: `${window.location.origin}`,
  responseType: 'code',
};
const config = {
  app: {
    loginURL: `${window.location.origin}/login?redirect_uri=${window.location.origin}%2Flogin&response_type=code&client_id=${cognito.appClientId}&identity_provider=COGNITO&scopes=openid%2Cprofile%2Cemail`,
  },
  Amplify: {
    Auth: {
      identityPoolId: cognito.identityPoolId,
      region: cognito.region,
      userPoolId: cognito.userPoolId,
      userPoolWebClientId: cognito.appClientId,
      oauth,
    },
    Storage: {
      AWSS3: {
        bucket: 'alps-uploads', //REQUIRED -  Amazon S3 bucket
        region: cognito.region, //OPTIONAL -  Amazon service region
      },
    },
    API: {
      endpoints: [
        {
          name: 'Alps',
          endpoint: process.env.REACT_APP_API_BASE_PATH,
          custom_header: async () => {
            // return { Authorization : 'token' }
            // Alternatively, with Cognito User Pools use this:
            return {
              Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: 'S3',
          endpoint: 'https://s3.us-west-2.amazonaws.com',
          custom_header: async () => {
            // return { Authorization : 'token' }
            // Alternatively, with Cognito User Pools use this:
            return {
              Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: 'Logout',
          endpoint: `https://${cognito.authURL}/logout?client_id=${cognito.appClientId}`,
          custom_header: async () => {
            // return { Authorization : 'token' }
            // Alternatively, with Cognito User Pools use this:
            return {
              Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    },
  },
};

export default config;
