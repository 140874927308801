import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../actions/userActions';
import PropTypes from 'prop-types';
import { makeQueryString } from '../../query-params';
import { configShowProject } from 'helpers/Helpers';

const cognito = {
  authURL: process.env.REACT_APP_AUTH_BASE_PATH,
  region: process.env.REACT_APP_COGNITO_REGION,
  domainPrefix: process.env.REACT_APP_COGNITO_DOMAIN_PREFIX,
  appClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  idp_identifier: process.env.REACT_APP_COGNITO_IDP_IDENTIFIER,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
};

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: {},
      isLoaded: true,
      formNavOptions: configShowProject().formNavOptions,
    };
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.login();
    }
  };

  generateLoginUrl = () => {
    let setScope = ['openid', 'aws.cognito.signin.user.admin', 'email'].join(
      ' ',
    );
    let loginUrl = window.encodeURI(
      `https://${cognito.authURL}/authorize?${makeQueryString({
        response_type: 'code',
        redirect_uri: `${window.location.origin}/login`,
        client_id: cognito.appClientId,
        identity_provider: cognito.idp_identifier,
        scope: setScope,
      })}`,
    );
    return loginUrl;
  };

  goToOkta = () => {
    this.setState({
      isLoaded: true,
    });
    setTimeout(() => {
      window.location = this.generateLoginUrl();
    }, 100);
  };

  componentDidMount() {}

  render() {
    return (
      <div
        className="landing"
        style={{ backgroundImage: `url(./AlpsMountains.jpg)` }}
      >
        <div className="overlay">
          {this.state.isLoaded ? (
            <React.Fragment>
              <div className="container animated fadeInUp delay-1s">
                <div className="container-img">
                  <img src="/alps-logo.png" alt="logo" />
                </div>

                <h3 className="white">Access ALPS Dashboard</h3>
                <div className="card">
                  <button className="login-btn" onClick={() => this.goToOkta()}>
                    Log In
                  </button>
                </div>
                <h3 className="white">Download Forms</h3>
                <div className="card">
                  <ul>
                    {this.state.formNavOptions.map((f) => (
                      <li key={f.title}>
                        <a
                          href={f.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={f.image} alt={f.title} />
                          {f.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="full-loading">
              <div className="spinner"></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
  currentUser: PropTypes.object.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, { getCurrentUser })(Landing);
