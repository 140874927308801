import strings from '../config/strings';

export function addNewAppVersionModalContent() {
  return {
    title: 'Add New App Version',
    fields: [
      {
        type: 'text',
        name: 'version_number',
        label: 'Version Number',
        placeholder: 'Enter Version Number',
        value: '',
        help: 'Numbers and dots only',
      },
    ],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: '', label: 'Create App Version' },
    ],
  };
}

export function editAppVersionModalContent(selectedVersion) {
  return {
    title: 'Edit Version',
    fields: [
      {
        type: 'text',
        name: 'version_number',
        label: 'Version Number',
        placeholder: 'Enter Version Number',
        value: selectedVersion.submission_label,
        help: 'Numbers and dots only',
      },
    ],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: '', label: 'Update App Version' },
    ],
  };
}

export function editTestVersionModalContent(selectedVersion) {
  return {
    title: 'Edit Test Version',
    fields: [
      {
        type: 'text',
        name: 'version_number',
        label: 'Version Number',
        value: selectedVersion.submission_label.split(' - ')[0],
        placeholder: 'Enter Version Number',
        help: 'Numbers and dots only',
      },
      {
        type: 'text',
        name: 'build_number',
        label: 'Build Number',
        value: selectedVersion.submission_label.split(' - ')[1],
        placeholder: 'Enter Build Number',
        help: 'Numbers and dots only',
      },
    ],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: '', label: 'Update Test Version' },
    ],
  };
}

export function addNewTestVersionModalContent() {
  return {
    title: 'Add New Test Version',
    fields: [
      {
        type: 'text',
        name: 'version_number',
        label: 'Version Number',
        value: '',
        placeholder: 'Enter Version Number',
        help: 'Numbers and dots only',
      },
      {
        type: 'text',
        name: 'build_number',
        label: 'Build Number',
        value: '',
        placeholder: 'Enter Build Number',
        help: 'Numbers and dots only',
      },
    ],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: '', label: 'Create Test Version' },
    ],
  };
}

export function addUserToProjectModalContent(content) {
  return {
    title: 'Add User to Existing Projects',
    search: 'Search projects...',
    fields: [
      {
        type: 'multicheck',
        name: 'user_to_projects',
        label: 'Select Projects',
        data: content,
        value: '',
        help: '',
      },
    ],
    commands: [],
  };
}

export function removeUserFromProjectModalContent(content, user) {
  return {
    title: `Remove User from Project`,
    body: `Are you sure you want to remove ${user} from ${content.project_name}?`,
    data: content,
    fields: [],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: 'btn-danger', label: 'Remove' },
    ],
  };
}

export function archiveProjectModalContent(content) {
  return {
    title: 'Archive Project',
    body: `Are you sure you want to archive this project?`,
    data: content,
    fields: [],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: 'btn-danger', label: 'Archive' },
    ],
  };
}

export function restoreProjectModalContent(content) {
  return {
    title: 'Restore Project',
    body: `Are you sure you want to restore this project?`,
    data: content,
    fields: [],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: 'btn-primary', label: 'Restore' },
    ],
  };
}

export function editProjectModalContent(project) {
  return {
    title: 'Edit Project',
    fields: [
      {
        type: 'text',
        name: 'project_name',
        label: 'Project Name',
        placeholder: 'Enter Project Name',
        value: project.project_name,
        help: '',
      },
    ],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: '', label: 'Update Project' },
    ],
  };
}

export function deleteProjectModalContent() {
  return {
    title: 'Delete Project',
    body: 'Are you sure you want to delete this project?',
    fields: [],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: 'btn-danger', label: 'Delete' },
    ],
  };
}

export function removeUserFromNotificationsModalContent(content, project) {
  return {
    title: `Remove User from Notifications`,
    body: `Are you sure you want to remove ${content.email} from ${project.project_name} project notifications?`,
    data: content.email,
    fields: [],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: 'btn-danger', label: 'Remove User' },
    ],
  };
}

export function goToS3BucketModalContent(content, selectedVersion) {
  let urlToS3 = '';

  if (selectedVersion.info_id.includes('test:')) {
    if (process.env.NODE_ENV === 'production') {
      urlToS3 = `https://s3.console.aws.amazon.com/s3/buckets/alps-uploads/${
        content.project_folder
      }/${strings.testServiceName[content.project_type]}/${
        selectedVersion.submission_label
      }/`;
    } else {
      urlToS3 = `https://s3.console.aws.amazon.com/s3/buckets/alps-uploads-staging/${
        content.project_folder
      }/${strings.testServiceName[content.project_type]}/${
        selectedVersion.submission_label
      }/`;
    }
  } else {
    if (process.env.NODE_ENV === 'production') {
      urlToS3 = `https://s3.console.aws.amazon.com/s3/buckets/alps-uploads/${content.project_folder}/${selectedVersion.submission_label}/`;
    } else {
      urlToS3 = `https://s3.console.aws.amazon.com/s3/buckets/alps-uploads-staging/${content.project_folder}/${selectedVersion.submission_label}/`;
    }
  }

  return {
    title: 'Go to S3 Bucket Folder',
    body: '',
    dataArray: [
      {
        text: 'Login into AWS in a new tab.',
        link:
          'https://account.activedirectory.windowsazure.com/applications/signin/aws-wbt-dope-mobiledistribution/f8ca5b0d-a41e-4f41-8fe1-a524ea83a829?tenantId=268aeb03-c10a-48f7-b2c6-5dd950fe8c5c',
        type: 'button white',
        linkText: 'Login to AWS',
      },
      {
        text: 'After logging in, click go to bucket.',
        link: encodeURI(urlToS3),
        type: 'button white',
        linkText: 'Go To Folder',
      },
    ],
    commands: [],
  };
}

export function userNotificationsModalContentEmpty() {
  return {
    title: 'User Notifications',
    fields: [
      {
        type: 'email',
        name: 'email',
        label: 'Email',
        value: '',
        placeholder: 'Enter Email',
        help: '',
      },
      {
        type: 'text',
        name: 'first_name',
        label: 'First Name',
        value: '',
        placeholder: 'Enter First Name',
        help: '',
      },
      {
        type: 'text',
        name: 'last_name',
        label: 'Last Name',
        value: '',
        placeholder: 'Enter Last Name',
        help: '',
      },
      {
        type: 'select',
        name: 'subscription_type',
        label: 'Type',
        options: [
          { label: 'All', value: 'ALL' },
          { label: 'Status Change', value: 'STATUS_CHANGE' },
          { label: 'Rejections Only', value: 'REJECT_ONLY' },
        ],
        value: 'ALL',
        help: '',
      },
    ],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: '', label: 'Add User Notification' },
    ],
  };
}

export function userNotificationsModalContent(content) {
  return {
    title: `Edit Notifications`,
    contentObject: content,
    fields: [
      {
        type: 'email',
        name: 'email',
        label: 'Email',
        value: content.email,
        placeholder: 'Enter Email',
        help: '',
      },
      {
        type: 'text',
        name: 'first_name',
        label: 'First Name',
        value: content.first_name,
        placeholder: 'Enter First Name',
        help: '',
      },
      {
        type: 'text',
        name: 'last_name',
        label: 'Last Name',
        value: content.last_name,
        placeholder: 'Enter Last Name',
        help: '',
      },
      {
        type: 'select',
        name: 'subscription_type',
        label: 'Type',
        options: [
          { label: 'All', value: 'ALL' },
          { label: 'Status Change', value: 'STATUS_CHANGE' },
          { label: 'Rejections Only', value: 'REJECT_ONLY' },
        ],
        value: content.subscription_type,
        help: '',
      },
    ],
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: '', label: 'Update Notifications' },
    ],
  };
}

export function uploadForQAModalContent(title) {
  return {
    title: 'Are you sure?',
    body: `If ${title} is final and needs to be entered into the console for testing, select “Upload”. If you want to wait to submit this with the other items, select “Cancel.” `,
    commands: [
      { type: 'btn-secondary', label: 'Cancel' },
      { type: '', label: 'Upload' },
    ],
  };
}
