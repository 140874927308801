import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProjects, createProject } from '../../actions/projectActions';
import { getCurrentUser } from '../../actions/userActions';
import { configProjectsTableHeaders } from 'helpers/Helpers';
import Header from '../layout/Header';
import Modal from '../layout/Modal';
import TableSort from '../layout/TableSort';

import _ from 'lodash';

class Projects extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      projectList: [],
      isLoaded: false,
      loadingText: 'Loading Projects',
      updateComponent: false,
      activeList: [],
      archivedList: [],
      modalContent: {},
      userType: JSON.parse(localStorage.getItem('current_user')).userType,
      sortedActiveList: [],
      sortedArchivedList: [],
      sortBy: {
        label: 'Name',
        value: 'project_name',
      },
      tableHeaders: configProjectsTableHeaders().tableHeaders,
      sortAsc: true,
      alert: {},
    };
  }

  goTo = (id) => {
    this.props.history.push(`project/${id}`);
  };

  setModalContent = () => {
    this.setState({
      modalContent: {
        title: 'Add New Project',
        fields: [
          {
            type: 'text',
            name: 'project_name',
            label: 'Project Name',
            value: '',
            placeholder: 'Enter Project Name',
            help: '',
          },
          {
            type: 'select',
            name: 'project_platform',
            label: 'Platform',
            value: '',
            help: '',
            options: [
              { label: 'iOS', value: 'ios' },
              { label: 'Google Play', value: 'android' },
            ],
          },
        ],
        commands: [
          { type: 'btn-secondary', label: 'Cancel' },
          { type: '', label: 'Create Project' },
        ],
      },
    });
  };

  handleModalAction = async (action, object) => {
    if (action === 'Cancel') {
      this.setState({
        modalContent: {},
      });
    }
    if (action === 'Create Project') {
      this.props.createProject(object);
      this.setState({
        modalContent: {},
        updateComponent: true,
        loadingText: 'Creating Project',
        isLoaded: false,
      });
    }
  };

  getProjects = async () => {
    await this.props.getProjects();
    if (
      (!_.isEmpty(this.props.projects) && this.props.projects.Items.length) ===
      0
    ) {
      this.props.history.push('placeholder');
    }

    let lowerCasedProjectList = this.props.projects.Items.map((i) => {
      return {
        project_name_lowercased: i.project_name.toLowerCase(),
        project_name: i.project_name,
        project_type: i.project_type,
        project_id: i.project_id,
      };
    });
    this.setState({
      projectList: _.orderBy(lowerCasedProjectList, 'project_name_lowercased'),
    });

    this.setState({
      activeList: this.props.projects.Items.filter(
        (i) => i.status === 'ACTIVE',
      ),
      archivedList: this.props.projects.Items.filter(
        (i) => i.status === 'ARCHIVED',
      ),
      isLoaded: true,
      updateComponent: false,
    });
  };

  componentDidMount() {
    this.getProjects();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevState, prevProps) {
    let previousProjectList = prevState.projects.Items;
    
    try {
      if (prevProps.updateComponent) {
        this.setState({ isLoaded: false, updateComponent: false });
        this.getProjects();

        if (previousProjectList.length !== this.props.projects.Items.length) {
          this.props.history.push(
            `/project/${this.props.projects.Items.pop().project_id}`,
          );
        }
      }
    } catch (error) {}
  }

  render() {
    const {
      isLoaded,
      loadingText,
      projectList,
      modalContent,
      userType,
      activeList,
      archivedList,
      tableHeaders,
      sortBy,
      sortAsc,
    } = this.state;
    return (
      <React.Fragment>
        <Header history={this.props.history} items={projectList}></Header>

        <div className="container projects">
          {isLoaded ? (
            <React.Fragment>
              {!_.isEmpty(modalContent) && (
                <Modal
                  content={modalContent}
                  modalAction={this.handleModalAction}
                ></Modal>
              )}
              <h1 className="d-flex">
                Projects
                {userType === 'admin' && (
                  <button onClick={() => this.setModalContent()}>
                    <i className="ion-plus-round" /> Project
                  </button>
                )}
              </h1>

              <div className={activeList.length > 0 ? 'table card' : 'hidden'}>
                <div className="d-flex card-header">
                  <h2>
                    <i className="ion-record success small" /> Active Projects
                  </h2>
                </div>
                <TableSort
                  headers={tableHeaders}
                  items={activeList}
                  sortBy={sortBy}
                  sortAsc={sortAsc}
                  goTo={this.goTo}
                />
              </div>

              <div
                className={archivedList.length > 0 ? 'table card' : 'hidden'}
              >
                <div className="d-flex card-header">
                  <h2>
                    <i className="ion-record danger small" /> Archived Projects
                  </h2>
                </div>

                <TableSort
                  headers={tableHeaders}
                  items={archivedList}
                  sortBy={sortBy}
                  sortAsc={sortAsc}
                  goTo={this.goTo}
                />
              </div>
            </React.Fragment>
          ) : (
            <div className="full-loading">
              <div className="spinner"></div>
              <h3>{loadingText}</h3>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

Projects.propTypes = {
  getCurrentUser: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  createProject: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  alert: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  projects: state.project.projects,
});

export default connect(mapStateToProps, {
  getCurrentUser,
  getProjects,
  createProject,
})(Projects);
