import React, { Component } from 'react';

class NotificationRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionType: {},
    };
  }

  async componentDidMount() {
    const subscriptionTypesArray = [
      { label: 'All', value: 'ALL' },
      { label: 'Status Change', value: 'STATUS_CHANGE' },
      { label: 'Rejections Only', value: 'REJECT_ONLY' },
    ];
    this.setState({
      subscriptionType: subscriptionTypesArray.filter(
        (s) => s.value === this.props.content.subscription_type,
      )[0],
    });
  }

  handleEditContent = (content) => {
    this.props.handleEditContent(content);
  };

  handleRemoveUser = (content) => {
    this.props.handleRemoveUser(content);
  };

  render() {
    let { last_name, email, first_name } = this.props.content;
    return (
      <React.Fragment>
        <tbody>
          <tr>
            <td>{first_name}</td>
            <td>{last_name}</td>
            <td>{email}</td>
            <td>
              {this.state.subscriptionType && this.state.subscriptionType.label}
            </td>
            <td>
              <button
                className="btn-secondary"
                onClick={() => this.handleEditContent(this.props.content)}
              >
                <i className="ion-edit"></i>
              </button>
              <button
                className="btn-secondary"
                onClick={() => this.handleRemoveUser(this.props.content)}
              >
                <i className="ion-trash-b"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </React.Fragment>
    );
  }
}

export default NotificationRow;
