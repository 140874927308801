import { SET_ALERT, DELETE_ALERT } from '../actions/types';

const initialState = {
  alert: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case DELETE_ALERT:
      return {
        ...state,
        alert: action.payload,
      };

    default:
      return state;
  }
}
