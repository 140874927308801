import {
  GET_USERS,
  GET_USER,
  GET_CURRENT_USER,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  ADD_USER_TO_PROJECT,
  REMOVE_USER_FROM_PROJECT,
} from '../actions/types';

const initialState = {
  users: {},
  user: {},
  currentUser: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case CREATE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          Items: [...state.users.Items, action.payload],
        },
      };
    case UPDATE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          Items: [...state.users.Items, action.payload],
        },
      };
    case DELETE_USER:
      return {
        ...state,
        users: action.payload,
      };
    case ADD_USER_TO_PROJECT:
      return {
        ...state,
        user: action.payload,
      };
    case REMOVE_USER_FROM_PROJECT:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
