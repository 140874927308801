import React, { Component } from 'react';

class Dropdown extends Component {
  state = {
    toggledDropdown: false,
  };

  render() {
    return (
      <div
        className="dropdown-component"
        onMouseEnter={() =>
          this.setState({
            toggledDropdown: true,
          })
        }
        onMouseLeave={() =>
          this.setState({
            toggledDropdown: false,
          })
        }
      >
        {this.props.type === 'icon' ? (
          <button className="overflow-btn">
            <i className="ion-android-more-vertical" />
          </button>
        ) : (
          <button
            className={
              this.props.type === 'dropdown'
                ? 'btn-outline d-flex'
                : 'btn-dropdown'
            }
          >
            {this.props.title}
            <i className="ion-arrow-down-b small" />
          </button>
        )}

        <div
          className={
            this.state.toggledDropdown
              ? this.props.type === 'icon'
                ? 'dropdown-component-container dropdown overflow-container'
                : 'dropdown-component-container dropdown'
              : 'hidden'
          }
        >
          <React.Fragment>
            {this.props.options.map(
              (d, index) =>
                d.label !== this.props.title &&
                d.value !== this.props.title && (
                  <div
                    key={index}
                    onClick={() => this.props.dropdownAction(d, this.props)}
                  >
                    {d.label}
                  </div>
                ),
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default Dropdown;
