import axios from 'axios';

export const sendPresignedPost = (presignedUrl, formData) =>
  axios.post(presignedUrl, formData);

export const uploadFileToUrl = (presignedPostReq, file) => {
  const { url, fields } = presignedPostReq;

  const formData = new FormData();
  Object.keys(fields).forEach(key => {
    formData.append(key, fields[key]);
  });

  formData.append('file', file);
  return sendPresignedPost(url, formData);
};
