import React, { Component } from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './App.scss';
import { deleteAlert } from './actions/alertActions';
import { getCurrentUser } from './actions/userActions';
import { getProjects } from './actions/projectActions';
import Projects from './components/projects/Projects';
import Users from './components/users/Users';
import ShowUser from './components/users/ShowUser';
import ShowProject from './components/projects/ShowProject';
import Landing from './components/pages/Landing';
import Footer from './components/layout/Footer';
import Placeholder from './components/pages/Placeholder';
import Logout from './components/pages/Logout';
import Login from './components/pages/Login';

import Amplify, { Hub, Auth } from 'aws-amplify';
import config from './config';
import _ from 'lodash';

function isLoggedIn() {
  if (localStorage.getItem('current_user')) {
    return true;
  } else {
    return false;
  }
}
function isAdmin() {
  if (localStorage.getItem('current_user')) {
    if (JSON.parse(localStorage.getItem('current_user')).userType === 'admin') {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

class App extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      alert: null,
    };

    Hub.listen('auth', (capsule) => {
      switch (capsule.payload.event) {
        case 'cognitoHostedUI':
          return Auth.currentAuthenticatedUser({
            bypassCache: false,
          }).then(async (data) => {
            try {
              localStorage.setItem(
                'userName',
                data.signInUserSession.idToken.payload.name,
              );
              localStorage.setItem(
                'userEmail',
                data.signInUserSession.idToken.payload.email,
              );
              localStorage.setItem(
                'token',
                JSON.stringify(data.signInUserSession.idToken),
              );
              this.props.getCurrentUser();
            } catch (e) {
              alert('error', e);
            }
          });
        default:
          return;
      }
    });

    try {
      Amplify.configure(config.Amplify);
    } catch (error) {
      console.error(error);
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.alert !== this.props.alert) {
      if (this.props.alert && !_.isEmpty(this.props.alert)) {
        this.setState({ isLoaded: true, alert: this.props.alert });

        setTimeout(() => {
          this.setState({
            alert: {},
          });
          this.props.deleteAlert();
        }, 3000);
      }
    }
  }

  render() {
    const { alert } = this.state;
    return (
      <React.Fragment>
        {alert && (
          <div
            className={
              !_.isEmpty(alert)
                ? `alert ${alert.type} animated slideInRight faster d-flex`
                : `alert ${alert.type} animated fadeOutDown faster d-flex`
            }
            onClick={() =>
              this.setState({
                alert: {},
              })
            }
          >
            <div>
              <i className={alert.icon} /> {alert.message}
            </div>

            <i className="ion-android-close"></i>
          </div>
        )}

        <Router>
          {localStorage.getItem('current_user') ? (
            <div className="App">
              <Switch>
                <Route
                  exact
                  path="/project/:id"
                  render={(props) =>
                    isLoggedIn() ? (
                      <ShowProject {...props} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  exact
                  path="/projects"
                  render={(props) =>
                    isLoggedIn() ? <Projects {...props} /> : <Redirect to="/" />
                  }
                />
                <Route
                  exact
                  path="/placeholder"
                  render={(props) =>
                    isLoggedIn() ? (
                      <Placeholder {...props} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  exact
                  path="/users"
                  render={(props) =>
                    isAdmin() ? <Users {...props} /> : <Redirect to="/" />
                  }
                />
                <Route
                  exact
                  path="/user/:id"
                  render={(props) =>
                    isAdmin() ? <ShowUser {...props} /> : <Redirect to="/" />
                  }
                />
                <Route
                  exact
                  path="/landing"
                  render={(props) =>
                    !isLoggedIn() ? (
                      <Landing {...props} />
                    ) : (
                      <Redirect to="/projects" />
                    )
                  }
                />
                <Route
                  exact
                  path="/"
                  render={(props) =>
                    isLoggedIn() ? (
                      <Redirect to={'/' + this.props.currentUser.viewType} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  exact
                  path="/login"
                  render={(props) =>
                    isLoggedIn() ? <Login {...props} /> : <Redirect to="/" />
                  }
                />

                <Route
                  exact
                  path="/logout"
                  render={(props) =>
                    isLoggedIn() ? <Logout {...props} /> : <Redirect to="/" />
                  }
                />

                <Redirect to="/projects" />
              </Switch>
            </div>
          ) : (
            <Landing></Landing>
          )}
        </Router>
        <Footer />
      </React.Fragment>
    );
  }
}

App.propTypes = {
  currentUser: PropTypes.object.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  alert: PropTypes.object,
  deleteAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  projects: state.project.projects,
  alert: state.alert.alert,
});

export default connect(mapStateToProps, {
  getCurrentUser,
  getProjects,
  deleteAlert,
})(App);
