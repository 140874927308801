import { UPLOAD_FILE, UPLOAD_RESOURCE, SET_ALERT } from './types';
import {
  updateProjectApi,
  getPresignedRequest,
  updateAssetStatusApi,
} from '../support/alpsApi';
import { uploadFileToUrl } from '../support/s3Api';
import _ from 'lodash';

export const createUploadFileAction = (
  projectId,
  projectFolder,
  file,
  testFolder,
  versionLabel,
  assetType,
  versionId,
  itemPath,
  project,
) => async (dispatch) => {
  //console.log('project props///////', project.project_name);
  //const itemName = itemPath;
  let res = {};
  /* console.log('s3App', `${projectFolder}/${versionLabel}/${assetType}`);
  console.log(
    's3Test',
    `${projectFolder}/${testFolder}/${versionLabel}/${file.name}`,
  ); */
  try {
    const s3Key = testFolder
      ? `${projectFolder}/${testFolder}/${versionLabel}/${file.name}`
      : `${projectFolder}/${versionLabel}/${assetType}/${file.name}`;

    res = await getPresignedRequest(s3Key, projectId)
      .then((presignedPostReq) => {
        return uploadFileToUrl(presignedPostReq, file);
      })
      .then(() => {
        return updateAssetStatusApi(
          projectId,
          versionId,
          itemPath,
          { value: 'UPLOADED' },
          file.name,
          new Date(),
        );
      });

    dispatch({
      type: UPLOAD_FILE,
      payload: [res, itemPath],
    });

    if (!_.isEmpty(res)) {
      setTimeout(() => {
        dispatch({
          type: SET_ALERT,
          payload: {
            type: 'success',
            message: 'File successfully uploaded.',
            icon: 'ion-checkmark-circled',
          },
        });
      }, 1000);
    }
  } catch (error) {
    alert(error);
  }
};

export const createUploadResourceAction = (
  projectId,
  projectFolder,
  file,
  projectName,
) => async (dispatch) => {
  const s3Key = `${projectFolder}/Resources/${file.name}`;

  const res = await getPresignedRequest(s3Key, projectId)
    .then((presignedPostReq) => {
      return uploadFileToUrl(presignedPostReq, file);
    })
    .then(() => {
      return updateProjectApi(projectId, 'resource_path', s3Key);
    });

  dispatch({
    type: UPLOAD_RESOURCE,
    payload: [res, null],
  });
};
