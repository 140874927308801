import React, { Component } from 'react';
import _ from 'lodash';

class TableSortUsers extends Component {
  state = {
    headers: this.props.headers,
    items: this.props.items.map((i) => {
      return {
        ...i,
        user_id: i.user_id.toLowerCase(),
        first_name: i.first_name.toLowerCase(),
        last_name: i.last_name.toLowerCase(),
      };
    }),
    sortBy: this.props.sortBy,
    sortAsc: this.props.sortAsc,
  };

  goTo = (id) => {
    this.props.goTo(id);
  };

  setSort = (sort) => {
    if (sort.value === this.state.sortBy.value) {
      this.setState({
        items: _.reverse(this.state.items),
        sortAsc: false,
      });
    } else {
      this.setState({
        items: _.orderBy(this.state.items, sort.value),
        sortAsc: true,
      });
    }
    this.setState({
      sortBy: sort,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({
        items: this.props.items,
      });
    }
  }

  render() {
    const { headers, items, sortBy } = this.state;
    return (
      <React.Fragment>
        <div className="table-row table-header table-users">
          {headers.map((t) => (
            <div
              className="table-cell"
              key={t.value}
              onClick={() => this.setSort(t)}
            >
              {t.label}
              <img
                alt={t.label}
                src="./SortButton.svg"
                className={t.value === sortBy.value ? '' : 'hidden'}
              />
            </div>
          ))}
        </div>
        {items.map((p) => (
          <div className="table-row" key={p.user_id}>
            <div className="table-cell" onClick={() => this.goTo(p.user_id)}>
              <span>{p.user_id}</span>
            </div>

            <div className="table-cell" onClick={() => this.goTo(p.user_id)}>
              <span>{p.first_name}</span>
            </div>
            <div className="table-cell" onClick={() => this.goTo(p.user_id)}>
              <span>{p.last_name}</span>
            </div>
            <div className="table-cell">
              <button
                className="btn-secondary"
                onClick={() => this.props.editContent(p)}
              >
                <i className="ion-edit"></i>
              </button>
              <button
                className="btn-secondary"
                onClick={() => this.props.deleteContent(p)}
              >
                <i className="ion-trash-b"></i>
              </button>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default TableSortUsers;
