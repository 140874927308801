import { SET_ALERT, DELETE_ALERT } from './types';

export const setAlert = alert => async dispatch => {
  dispatch({
    type: SET_ALERT,
    payload: alert,
  });
};

export const deleteAlert = () => async dispatch => {
  dispatch({
    type: DELETE_ALERT,
    payload: {},
  });
};
