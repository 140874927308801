import {
  GET_USERS,
  GET_USER,
  GET_CURRENT_USER,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  ADD_USER_TO_PROJECT,
  REMOVE_USER_FROM_PROJECT,
  SET_ALERT,
} from './types';

import { API } from 'aws-amplify';
const instance = API;

export const getUsers = () => async (dispatch) => {
  let res = {};
  try {
    res = await instance.get('Alps', '/user', {});

    dispatch({
      type: GET_USERS,
      payload: res,
    });
  } catch (error) {
    alert(error + ' (Refresh page or email us with screenshot).');
    window.location.assign('/logout');
  }
};

export const getUser = (id) => async (dispatch) => {
  let res = {};
  try {
    res = await instance.get('Alps', `/user/${id}`, {});
    dispatch({
      type: GET_USER,
      payload: res,
    });
  } catch (error) {
    alert(error + ' (Refresh page or email us with screenshot).');
    window.location.assign('/logout');
  }
};

export const createUser = (user) => async (dispatch) => {
  let res = {};
  try {
    res = await instance.post(
      'Alps',
      '/user',
      {
        body: {
          user_id: user.fields[0].value,
          first_name: user.fields[1].value,
          last_name: user.fields[2].value,
          projects: [],
        },
      },
      {},
    );
    dispatch({
      type: CREATE_USER,
      payload: res,
    });
    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `Added ${user.fields[0].value}.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  let res = {};
  try {
    res = await instance.del('Alps', `/user/${id}`, {});

    dispatch({
      type: DELETE_USER,
      payload: res,
    });

    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `Deleted ${id}.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const addUserToProject = (userId, projectId, projectName) => async (
  dispatch,
) => {
  let res = {};
  try {
    res = await instance.post(
      'Alps',
      `/user/${userId}/project/${projectId}`,
      {},
      {},
    );

    dispatch({
      type: ADD_USER_TO_PROJECT,
      payload: res,
    });

    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `${userId} can now access ${projectName}.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const removeUserFromProject = (userId, projectId, projectName) => async (
  dispatch,
) => {
  let res = {};

  try {
    res = await instance.del(
      'Alps',
      `/user/${userId}/project/${projectId}`,
      {},
    );

    dispatch({
      type: REMOVE_USER_FROM_PROJECT,
      payload: res,
    });

    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `${userId} access revoked for ${projectName}.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const updateUser = (user) => async (dispatch) => {
  let userId = user.fields[0].value;
  let key = user.fields[1].name;
  let value = user.fields[1].value;
  let key2 = user.fields[2].name;
  let value2 = user.fields[2].value;
  let res = {};

  try {
    if (key2) {
      res = await (instance.put(
        'Alps',
        `/user/${userId}`,
        { body: { [key]: value } },
        {},
      ),
      instance.put(
        'Alps',
        `/user/${userId}`,
        { body: { [key2]: value2 } },
        {},
      ));
    } else {
      res = await instance.put(
        'Alps',
        `/user/${userId}`,
        { body: { [key]: value } },
        {},
      );
    }

    dispatch({
      type: UPDATE_USER,
      payload: res,
    });

    setTimeout(() => {
      dispatch({
        type: SET_ALERT,
        payload: {
          type: 'success',
          message: `Updated user details.`,
          icon: 'ion-checkmark-circled',
        },
      });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: 'error',
        message: error.response.data.message,
        icon: 'ion-alert-circled',
      },
    });
  }
};

export const getCurrentUser = (password) => (dispatch) => {
  let currentUser = {};

  if (localStorage.getItem('current_user')) {
    currentUser = JSON.parse(localStorage.getItem('current_user'));
  } else {
    let currentUser = {
      email: localStorage.getItem('userEmail'),
      name: localStorage.getItem('userName'),
      loggedIn: true,
      userType: JSON.parse(localStorage.getItem('token')).payload[
        'cognito:groups'
      ].includes('admin')
        ? 'admin'
        : 'user',
    };
    localStorage.setItem('current_user', JSON.stringify(currentUser));
    localStorage.setItem('hideUploadForQA', false);
  }

  dispatch({ type: GET_CURRENT_USER, payload: currentUser });
};
