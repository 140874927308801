import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Modal from '../layout/Modal';
import Dropdown from '../layout/Dropdown';
import {
  createUploadFileAction,
  createUploadResourceAction,
} from '../../actions/uploadActions';
import { updateAssetStatus } from '../../actions/projectActions';
import _ from 'lodash';
import moment from 'moment';
import { uploadForQAModalContent } from '../../helpers/ModalContent';
import { getUploadedFiles, configShowProject } from 'helpers/Helpers';

function DropWell(props) {
  let acceptedFileFormat = '';
  if (props.allowedFileFormat) {
    acceptedFileFormat = props.allowedFileFormat;
  } else {
    acceptedFileFormat = '';
  }

  let {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: acceptedFileFormat,
    multiple: false,
  });

  let files = acceptedFiles.map((file) => (
    <span key={file.path}>{file.path}</span>
  ));

  const rejectedFile = rejectedFiles.map((file) => (
    <span key={file.path}>{file.path}</span>
  ));

  function resetDropZone() {
    props.hasFile('false');
    props.handleRerender();
  }

  if (acceptedFiles.length > 0) {
    if (props.name !== 'Developer Resources') {
      props.hasFile('true');
    }
  }

  if (rejectedFile.length > 0) {
    console.log('reject', rejectedFile);
  }

  function uploadFile() {
    props.handleFileUpload(acceptedFiles);
    if (props.name !== 'Developer Resources') {
      props.hasFile('false');
    }
  }

  return (
    <React.Fragment>
      <div
        className={
          files.length === 0
            ? 'input-container input-container-dropzone'
            : 'hidden'
        }
      >
        <div {...getRootProps({ className: 'dropzone' })}>
          <section>
            <input {...getInputProps()} />
            <span className="animated fadeIn">
              Drop
              {props.allowedFileFormat === 'application/zip' && (
                <React.Fragment>
                  <b>zip</b>
                </React.Fragment>
              )}
              {props.allowedFileFormat === 'image/png, image/jpeg' && (
                <React.Fragment>
                  <b>png, jpg</b>
                </React.Fragment>
              )}
              {props.allowedFileFormat === 'image/png' && (
                <React.Fragment>
                  <b>png</b>
                </React.Fragment>
              )}
              {props.allowedFileFormat ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' && (
                <React.Fragment>
                  <b>xls</b>
                </React.Fragment>
              )}
              {props.allowedFileFormat === '' && (
                <React.Fragment> </React.Fragment>
              )}
              {props.allowedFileFormat === undefined && (
                <React.Fragment> </React.Fragment>
              )}
              to Upload
            </span>
            <button>Browse</button>
          </section>
          {/* <section className={files.length === 0 ? 'hidden' : ''}>
            {files}
            <button className='btn-success white' onClick={uploadFile}>
              Upload
            </button>
          </section> */}
        </div>
        <small className="danger">
          {rejectedFiles.length > 1 && 'Please upload one file at a time.'}
          {rejectedFiles.length === 1 &&
            rejectedFile[0].key + ' is not the right file type.'}{' '}
        </small>
      </div>

      <div className={files.length === 0 ? 'hidden' : 'input-container'}>
        <div>
          <section>
            {files}
            <span className="upload-actions">
              <button className={props.loading ? 'btn-spinner' : 'hidden'}>
                <div className="spinner-btn"></div>
              </button>

              <span className={props.loading ? 'hidden' : ''}>
                <button className="btn-success" onClick={uploadFile}>
                  Upload
                </button>
                <i
                  className="ion-ios-close"
                  onClick={resetDropZone.bind(this)}
                />
              </span>
            </span>
          </section>
        </div>
        <small className="danger">
          {rejectedFiles.length > 1 && 'Please upload one file at a time.'}
          {rejectedFiles.length === 1 &&
            rejectedFile[0].key + ' is not the right file type.'}
        </small>
      </div>
    </React.Fragment>
  );
}

class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputAttributes: [],
      uploadedFiles: [],
      showDropWell: true,
      required: false,
      inputAttributeOptions: configShowProject().inputAttributeOptions,
      superSubmitOptions: configShowProject().superSubmitOptions,
      modalContent: {},
      currentFile: [],
      isLoading: false,
      dropdownLoading: false,
    };
  }

  createUploadedFilesArray = async () => {
    if (this.props.selectedVersion) {
      this.setState({
        uploadedFiles: getUploadedFiles(this.props.selectedVersion),
      });
    }
  };

  handleRerender = (rowObject) => {
    this.setState({
      showDropWell: false,
    });
    setTimeout(() => {
      this.setState({
        showDropWell: true,
        isLoading: false,
      });
    }, 500);
  };

  handleHasFile = (boolean) => {
    if (boolean === 'true') {
      this.props.setActiveRow(this.props.inputAttributes.name);
    } else {
      this.props.setActiveRow('none');
    }
  };

  handleFileUpload = async (file) => {
    this.setState({
      isLoading: true,
      hasFile: 'false',
    });

    if (this.props.inputAttributes.name !== 'Developer Resources') {
      let testFolder = null;
      if (this.props.selectedVersion.info_id.startsWith('test')) {
        if (this.props.project.project_type === 'ios') {
          testFolder = 'TestFlight';
        }
        if (this.props.project.project_type === 'android') {
          testFolder = 'Testings';
        }
      }

      this.props.createUploadFileAction(
        this.props.project.project_id,
        this.props.project.project_folder,
        file[0],
        testFolder,
        this.props.selectedVersion.submission_label,
        this.props.inputAttributes.name,
        this.props.selectedVersion.info_id,
        this.props.inputAttributes.name,
        //{ value: 'UPLOADED' },
        this.props.project,
      );
      this.props.updateComponent();
    } else {
      await this.props.createUploadResourceAction(
        this.props.project.project_id,
        this.props.project.project_folder,
        file[0],
        this.props.project.project_name,
      );
      this.props.updateComponent();
    }
  };

  handleDropdownAction = (action, item) => {
    this.setState({
      dropdownLoading: true,
    });

    this.props.updateAssetStatus(
      this.props.project.project_id,
      this.props.selectedVersion.info_id,
      this.props.inputAttributes.name,
      action,
      this.props.project.project_name,
      this.props.selectedVersion.submission_label,
    );
    this.props.updateComponent();
  };

  uploadForQA = () => {
    if (localStorage.getItem('hideUploadForQA') === 'false') {
      this.setState({
        modalContent: uploadForQAModalContent(
          this.props.inputAttributes.name.substring(3),
        ),
      });
    } else {
      this.props.rowAction(
        'Upload',
        this.props.project.project_id,
        this.props.selectedVersion.info_id,
        this.props.inputAttributes.name,
        { value: 'PENDING' },
      );
    }
  };

  handleModalAction = (action) => {
    if (action === 'Cancel') {
      this.setState({
        modalContent: {},
      });
    }
    if (action === 'Upload') {
      this.props.rowAction(
        'Upload',
        this.props.project.project_id,
        this.props.selectedVersion.info_id,
        this.props.inputAttributes.name,
        { value: 'PENDING' },
      );
      this.setState({
        modalContent: {},
      });
      localStorage.setItem('hideUploadForQA', true);
    }
  };

  getTitle = () => {
    try {
      return this.state.inputAttributeOptions.filter(
        (a) => a.value === this.props.inputAttributes.state,
      )[0].label;
    } catch (error) {}
  };

  async componentDidMount() {
    this.setState({
      inputAttributes: this.props.inputAttributes,
    });
    this.createUploadedFilesArray();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.inputAttributes !== this.props.inputAttributes) {
      this.setState({
        inputAttributes: this.props.inputAttributes,
      });
      setTimeout(() => {
        this.handleRerender();
      }, 100);
    }
    if (this.props.file.length > 0) {
      //Compare timestamp to see if nextProps.file and currentFile are the same
      if (this.props.file !== prevState.currentFile) {
        this.setState({
          currentFile: this.props.file,
        });
        setTimeout(() => {
          this.handleRerender();
          this.createUploadedFilesArray();
        }, 100);
      }
    }
    if (prevProps !== this.props) {
      this.setState({
        dropdownLoading: false,
      });
    }
  }

  convertDateTime = (date) => {
    const d = new Date(date);
    return d.toLocaleString();
  };

  render() {
    let {
      inputAttributes,
      showDropWell,
      inputAttributeOptions,
      modalContent,
      isLoading,
      dropdownLoading,
    } = this.state;
    return (
      <React.Fragment>
        {!_.isEmpty(modalContent) && (
          <tr>
            <td>
              <Modal
                content={modalContent}
                modalAction={this.handleModalAction}
              ></Modal>
            </td>
          </tr>
        )}
        {inputAttributes && (
          <tr>
            {inputAttributes.name !== 'Developer Resources' && (
              <td>
                {inputAttributes.displayLabel && inputAttributes.displayLabel}
                {inputAttributes.required && <span className="danger"> *</span>}
              </td>
            )}

            <td>
              {this.props.activeRow !== 'none' && (
                <div
                  className={
                    this.props.activeRow !== inputAttributes.name
                      ? `disabled-row ${this.props.activeRow}`
                      : 'd-none'
                  }
                ></div>
              )}

              {showDropWell ? (
                <DropWell
                  allowedFileFormat={inputAttributes.allowedFileFormat}
                  handleRerender={this.handleRerender}
                  handleFileUpload={this.handleFileUpload}
                  loading={isLoading}
                  hasFile={this.handleHasFile}
                  name={inputAttributes.name}
                />
              ) : (
                <div className="input-container">
                  <div>
                    <span>...</span>
                    <button className="btn-default">Browse</button>
                  </div>
                </div>
              )}
            </td>
            <td>
              <div>
                {inputAttributes.asset &&
                  inputAttributes.name !== 'Developer Resources' && (
                    <div>
                      <div className="filename">
                        {inputAttributes.asset.fileName}
                      </div>
                      <small
                        className={
                          inputAttributes.asset.uploadedBy ? 'faded' : 'hidden'
                        }
                      >
                        {inputAttributes.uploadedBy}{' '}
                        {moment(inputAttributes.asset.uploadedAt).format(
                          'MM/DD/YY hh:mmA',
                        )}
                      </small>
                    </div>
                  )}
                {inputAttributes &&
                  inputAttributes.name === 'Developer Resources' && (
                    <div>
                      <div className="filename">{inputAttributes.fileName}</div>
                      <small
                        className={
                          inputAttributes.uploadedBy ? 'faded' : 'hidden'
                        }
                      >
                        {inputAttributes.uploadedBy}{' '}
                        {moment(inputAttributes.uploadedAt).format(
                          'MM/DD/YY hh:mm A',
                        )}
                      </small>
                    </div>
                  )}
              </div>
            </td>
            <td className="td-section">
              {this.props.viewType === 'admin' ? (
                <React.Fragment>
                  <div className="uppercase">
                    {dropdownLoading ? (
                      <button className="spinner-btn dropdown-loading">
                        <div className="spinner"></div>
                      </button>
                    ) : (
                      <Dropdown
                        type="dropdown"
                        title={this.getTitle()}
                        options={inputAttributeOptions}
                        dropdownAction={this.handleDropdownAction}
                      ></Dropdown>
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  {inputAttributes.state === 'PROCESSED' && (
                    <i
                      className={
                        inputAttributes.state === 'PROCESSED'
                          ? 'ion-ios-checkmark success'
                          : 'ion-ios-checkmark white'
                      }
                    />
                  )}
                  {inputAttributes.state === 'REJECTED' && (
                    <i
                      className={
                        inputAttributes.state === 'REJECTED'
                          ? 'ion-record danger'
                          : ''
                      }
                    />
                  )}
                  {inputAttributes.state === 'UPLOADED' && (
                    <i
                      className={
                        inputAttributes.state === 'UPLOADED'
                          ? 'ion-record primary'
                          : ''
                      }
                    />
                  )}
                  {inputAttributes.state === 'PENDING' && (
                    <i
                      className={
                        inputAttributes.state === 'PENDING'
                          ? 'ion-record warning'
                          : ''
                      }
                    />
                  )}
                  {inputAttributes.state !== 'ITEM_REQUIRED' &&
                  inputAttributes.name !== 'Developer Resources' ? (
                    ` ${inputAttributes.state}`
                  ) : inputAttributes.required ? (
                    <span className="danger">Required</span>
                  ) : (
                    <span
                      className={
                        inputAttributes.name === 'Developer Resources'
                          ? 'hidden'
                          : 'faded'
                      }
                    >
                      <em>Optional</em>
                    </span>
                  )}
                </div>
              )}
            </td>
            <td>
              {this.props.viewType === 'admin' ? (
                <React.Fragment>
                  {inputAttributes.state === 'PENDING' && (
                    <small
                      className="faded expedited"
                      title="Requested ALPS to pre-process this asset. Useful for assets that take time to process."
                    >
                      <b>Requsted Q/A Upload</b>
                    </small>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {inputAttributes.state === 'UPLOADED' && (
                    <button
                      className="btn-rounded small"
                      title="Request ALPS to pre-process this asset. Useful for assets that take time to process."
                      onClick={() => this.uploadForQA()}
                    >
                      Request Q/A Upload
                    </button>
                  )}
                  {inputAttributes.state === 'PENDING' && (
                    <small
                      className="faded expedited"
                      title="Requested ALPS to pre-process this asset. Useful for assets that take time to process."
                    >
                      <b>Requsted Q/A Upload</b>
                    </small>
                  )}
                </React.Fragment>
              )}
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  file: state.file.file,
});

TableRow.propTypes = {
  createUploadFileAction: PropTypes.func.isRequired,
  createUploadResourceAction: PropTypes.func.isRequired,
  updateAssetStatus: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  activeRow: PropTypes.string,
};

export default connect(mapStateToProps, {
  createUploadFileAction,
  createUploadResourceAction,
  updateAssetStatus,
})(TableRow);
