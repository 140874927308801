import React, { Component } from 'react';
import TextInputGroup from './TextInputGroup';
import PropTypes from 'prop-types';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: '',
      content: {},
      search: '',
      searchList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  onSearchChange(e) {
    this.setState({
      search: e.target.value,
      searchList: this.props.content.fields[0].data.filter((d) =>
        d.project_name.toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    });
  }

  clearSearch = () => {
    this.setState({
      searchList: this.props.content.fields[0]
        ? this.props.content.fields[0].data
        : [],
      search: '',
    });
  };

  handleChange(e) {
    this.setState({
      content: {
        ...this.state.content,
        fields: this.state.content.fields.map((f) => {
          if (f.name === e.target.name) {
            return {
              ...f,
              value: e.target.value,
            };
          } else {
            return f;
          }
        }),
      },
    });
  }

  onChange = (e) => {
    const re = /^[0-9.]*$/;
    if (
      e.target.name === 'version_number' ||
      e.target.name === 'build_number'
    ) {
      if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({
          content: {
            ...this.state.content,
            fields: this.state.content.fields.map((f) => {
              if (f.name === e.target.name) {
                return {
                  ...f,
                  value: e.target.value,
                };
              } else {
                return f;
              }
            }),
          },
        });
      }
    } else {
      this.setState({
        content: {
          ...this.state.content,
          fields: this.state.content.fields.map((f) => {
            if (f.name === e.target.name) {
              return {
                ...f,
                value: e.target.value,
              };
            } else {
              return f;
            }
          }),
        },
      });
    }
  };

  async componentDidMount() {
    await this.setState({
      content: this.props.content && this.props.content,
      searchList:
        this.props.content.fields &&
        this.props.content.fields.length > 0 &&
        this.props.content.fields[0]
          ? this.props.content.fields[0].data
          : [],
    });
    this.state.content.fields &&
      this.state.content.fields.forEach((f) => {
        if (f.type === 'select') {
          //Check if there's a default value, if none, set to first index of available options
          if (f.value === '') {
            f.value = f.options[0].value;
          }
        }
      });
  }

  handleModalAction = (action, data, contentObject) => {
    if (action === 'Cancel') {
      this.props.modalAction(action);
    } else if (action === 'Delete') {
      this.props.modalAction(action, this.props.content.data);
    } else if (action === 'Remove') {
      this.props.modalAction(action, this.props.content.data);
    } else if (action === 'Add User To Project') {
      this.props.modalAction(action, data);
    } else if (action === 'Add User Notification') {
      this.props.modalAction(action, this.state.content, contentObject);
    } else if (action === 'Update Notifications') {
      this.props.modalAction(action, this.state.content);
    } else {
      this.props.modalAction(action, this.state.content);
    }
  };

  render() {
    return (
      <div className="modal">
        <div className="modal-content animated fadeInUp faster">
          <div className="modal-header">
            <h2>{this.state.content.title}</h2>
            <i
              className="ion-android-close large"
              onClick={() => this.props.modalAction('Cancel')}
            />
          </div>
          <div className="modal-body projects">
            {this.state.content.body && <p>{this.state.content.body}</p>}
            {this.state.content.dataArray &&
              this.state.content.dataArray.map((c, index) => (
                <div className="aws-steps" key={index}>
                  <p>
                    <b>{index + 1}</b> {c.text}
                  </p>
                  <a
                    className={c.type}
                    href={c.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {c.linkText}
                  </a>
                </div>
              ))}
            {this.state.content.fields &&
              this.state.content.fields.map((f, index) =>
                f.type === 'multicheck' ? (
                  <div key={index}>
                    <div className="active search-btn">
                      <i className="ion-search hide-mobile" />
                      <input
                        className="search hide-mobile"
                        value={this.state.search}
                        onChange={this.onSearchChange.bind(this)}
                        placeholder={this.props.content.search}
                      />
                      {this.state.search.length > 0 && (
                        <i
                          className="ion-ios-close"
                          onClick={this.clearSearch}
                        />
                      )}
                    </div>
                    <div className="table">
                      {this.state.searchList.map((d) => (
                        <div
                          className="table-row settings-table"
                          key={d.project_id}
                        >
                          <div className="table-cell center">
                            <i
                              className="ion-plus-round"
                              onClick={() =>
                                this.handleModalAction('Add User To Project', d)
                              }
                            ></i>
                          </div>

                          <div className="table-cell">
                            {d.project_type === 'ios' && (
                              <img src="/Apple.png" alt="iOS" />
                            )}
                            {d.project_type === 'android' && (
                              <img src="/GooglePlay.png" alt="Google Play" />
                            )}{' '}
                            {d.project_name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : f.type === 'select' ? (
                  <div className="form-group" key={index}>
                    <label>{f.label}</label>
                    <select
                      key={f.name}
                      className="form-select"
                      name={f.name}
                      value={f.value}
                      onChange={this.handleChange}
                    >
                      {f.options.map((o) => (
                        <option value={o.value} key={o.value}>
                          {o.label}
                        </option>
                      ))}
                    </select>

                    <small className="faded">{f.help}</small>
                  </div>
                ) : (
                  <div key={index}>
                    {f.className === 'title' ? (
                      <h3>{f.value}</h3>
                    ) : (
                      <TextInputGroup
                        key={index}
                        label={f.label}
                        name={f.name}
                        placeholder={f.placeholder || `Enter ${f.type}`}
                        type={f.type}
                        value={f.value || ''}
                        onChange={this.onChange}
                        help={f.help}
                      />
                    )}
                  </div>
                ),
              )}
          </div>
          <div className="modal-footer d-flex">
            {this.state.content.commands &&
              this.state.content.commands.map((c) => (
                <button
                  key={c.label}
                  className={c.type}
                  disabled={
                    c.label !== 'Cancel' &&
                    this.state.content.fields &&
                    this.state.content.fields.filter(
                      (f) => f.value.length === 0,
                    ).length !== 0
                  }
                  onClick={() => this.handleModalAction(c.label)}
                >
                  {c.label}
                </button>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onChange: PropTypes.func,
};

export default Modal;
