import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProjects } from '../../actions/projectActions';
import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
} from '../../actions/userActions';
import { deleteAlert } from '../../actions/alertActions';
import Header from '../layout/Header';
import Modal from '../layout/Modal';
import TableSortUsers from '../layout/TableSortUsers';
import _ from 'lodash';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList: [],
      projectList: [],
      isLoaded: false,
      modalContent: {},
      search: '',
      updateComponent: false,
      alert: {},
      sortBy: {
        label: 'Email',
        value: 'user_id',
      },
      tableHeaders: [
        {
          label: 'Email',
          value: 'user_id',
        },
        {
          label: 'First Name',
          value: 'first_name',
        },
        {
          label: 'Last Name',
          value: 'last_name',
        },
        {
          label: 'Actions',
          value: 'actions',
        },
      ],
      sortAsc: true,
    };
  }

  goTo = (id) => {
    this.props.history.push(`user/${id}`);
  };

  onChange = async (event) => {
    await this.setState({ search: event.target.value.substr(0, 20) });
    let userListTemp = this.props.users.Items.filter(
      (t) =>
        t.user_id.toLowerCase().search(this.state.search.toLowerCase()) !==
          -1 ||
        t.first_name.toLowerCase().search(this.state.search.toLowerCase()) !==
          -1 ||
        t.last_name.toLowerCase().search(this.state.search.toLowerCase()) !==
          -1,
    );
    this.setState({
      userList: _.orderBy(userListTemp, 'user_id'),
    });
  };

  clearSearch = () => {
    this.setState({
      search: '',
      userList: this.props.users.Items,
    });
  };

  setModalContent = () => {
    this.setState({
      modalContent: {
        title: 'Add New User',
        fields: [
          {
            type: 'email',
            name: 'email',
            label: 'Email',
            value: '',
            placeholder: 'Enter Email',
            help: '',
          },
          {
            type: 'text',
            name: 'first_name',
            label: 'First Name',
            value: '',
            placeholder: 'Enter First Name',
            help: '',
          },
          {
            type: 'text',
            name: 'last_name',
            label: 'Last Name',
            value: '',
            placeholder: 'Enter Last Name',
            help: '',
          },
        ],
        commands: [
          { type: 'btn-secondary', label: 'Cancel' },
          { type: '', label: 'Add' },
        ],
      },
    });
  };

  editContent = (content) => {
    this.setState({
      modalContent: {
        title: 'Edit User',
        fields: [
          {
            type: 'email',
            name: 'user_id',
            label: 'Email',
            className: 'title',
            value: content.user_id,
            placeholder: 'Enter Email',
            help: '',
          },
          {
            type: 'text',
            name: 'first_name',
            label: 'First Name',
            value: content.first_name,
            placeholder: 'Enter First Name',
            help: '',
          },
          {
            type: 'text',
            name: 'last_name',
            label: 'Last Name',
            value: content.last_name,
            placeholder: 'Enter Last Name',
            help: '',
          },
        ],
        commands: [
          { type: 'btn-secondary', label: 'Cancel' },
          { type: '', label: 'Save' },
        ],
      },
    });
  };

  deleteContent = (content) => {
    this.setState({
      modalContent: {
        title: 'Delete User',
        data: content,
        body: `Are you sure you want to delete ${content.first_name} ${content.last_name} (${content.user_id})?`,
        fields: [],
        commands: [
          { type: 'btn-secondary', label: 'Cancel' },
          { type: 'btn-danger', label: 'Delete' },
        ],
      },
    });
  };

  handleModalAction = (action, object) => {
    if (action === 'Cancel') {
      this.setState({
        modalContent: {},
      });
    }
    if (action === 'Add') {
      this.props.createUser(object);
      this.setState({
        modalContent: {},
        isLoaded: false,
        updateComponent: true,
      });
    }
    if (action === 'Save') {
      this.props.updateUser(object);
      this.setState({
        modalContent: {},
        isLoaded: false,
        updateComponent: true,
      });
    }
    if (action === 'Delete') {
      this.props.deleteUser(object.user_id);
      this.setState({
        modalContent: {},
        isLoaded: false,
        updateComponent: true,
      });
    }
  };

  getUsers = async () => {
    await this.props.getUsers();
    this.setState({
      userList: _.orderBy(this.props.users.Items, 'user_id'),
      projectList: this.props.projects.projects,
      isLoaded: true,
      updateComponent: false,
    });
  };

  async componentDidMount() {
    this.getUsers();
  }

  async componentDidUpdate(prevProps, prevState) {
    try {
      if (prevState.updateComponent) {
        this.getUsers();
      }
    } catch (error) {}
  }

  render() {
    const {
      isLoaded,
      userList,
      projectList,
      modalContent,
      search,
      sortBy,
      tableHeaders,
      sortAsc,
    } = this.state;

    return (
      <React.Fragment>
        {/* <div
          className="blurred-background"
          style={{
            backgroundImage: `url(/AlpsMountains.jpg)`,
          }}
        ></div> */}
        <Header history={this.props.history} items={projectList}></Header>

        <div className="container users projects">
          {/* {!_.isEmpty(alert) && (
            <div className={`alert ${alert.type} animated slideInUp faster`}>
              <i className={alert.icon} /> {alert.message}
            </div>
          )} */}
          {isLoaded ? (
            <React.Fragment>
              {!_.isEmpty(modalContent) && (
                <Modal
                  content={modalContent}
                  modalAction={this.handleModalAction}
                ></Modal>
              )}
              <h1 className="d-flex">
                <div className="active search-btn">
                  <i className="ion-search hide-mobile" />
                  <input
                    className="search hide-mobile"
                    value={search}
                    onChange={this.onChange.bind(this)}
                    placeholder="Search Users by Email..."
                  />
                  {this.state.search.length > 0 && (
                    <i className="ion-ios-close" onClick={this.clearSearch} />
                  )}
                </div>
                {/* Users */}
                <button onClick={() => this.setModalContent()}>
                  <i className="ion-plus-round" /> User
                </button>
              </h1>

              <div className="table card settings-table">
                <div className="d-flex card-header">
                  <h2>All Users</h2>
                </div>

                <TableSortUsers
                  headers={tableHeaders}
                  items={userList}
                  sortBy={sortBy}
                  sortAsc={sortAsc}
                  goTo={this.goTo}
                  editContent={this.editContent}
                  deleteContent={this.deleteContent}
                />

                {/* <div className="table-row table-header">
                  <div className="table-cell">Email</div>

                  <div className="table-cell">First Name</div>
                  <div className="table-cell">Last Name</div>
                  <div className="table-cell">Actions</div>
                </div>
                {userList.map(u => (
                  <div className="table-row settings-table" key={u.user_id}>
                    <div
                      className="table-cell"
                      onClick={() => this.goTo(u.user_id)}
                    >
                      {u.user_id}
                    </div>

                    <div
                      className="table-cell"
                      onClick={() => this.goTo(u.user_id)}
                    >
                      {u.first_name}
                    </div>
                    <div
                      className="table-cell"
                      onClick={() => this.goTo(u.user_id)}
                    >
                      {u.last_name}
                    </div>
                    <div className="table-cell">
                      <button
                        className="btn-secondary"
                        onClick={() => this.editContent(u)}
                      >
                        <i className="ion-edit"></i>
                      </button>
                      <button
                        className="btn-secondary"
                        onClick={() => this.deleteContent(u)}
                      >
                        <i className="ion-trash-b"></i>
                      </button>
                    </div>
                  </div>
                ))} */}
              </div>
            </React.Fragment>
          ) : (
            <div className="full-loading">
              <div className="spinner" />
              <h3>Loading Users</h3>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  getProjects: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  projects: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  deleteAlert: PropTypes.func.isRequired,
  alert: PropTypes.object,
};

const mapStateToProps = (state) => ({
  users: state.user.users,
  projects: state.project.projects,
  alert: state.alert.alert,
});

export default connect(mapStateToProps, {
  getUsers,
  getProjects,
  createUser,
  updateUser,
  deleteUser,
  deleteAlert,
})(Users);
