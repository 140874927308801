export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_VERSION_LOG = 'GET_VERSION_LOG';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_PROJECT_ASSET_STATUS = 'UPDATE_PROJECT_ASSET_STATUS';
export const CREATE_PROJECT_VERSION = 'CREATE_PROJECT_VERSION';
export const UPDATE_PROJECT_VERSION_STATUS = 'UPDATE_PROJECT_VERSION_STATUS';
export const UPDATE_PROJECT_VERSION_NOTES = 'UPDATE_PROJECT_VERSION_NOTES';
export const GET_PROJECT_NOTIFICATIONS = 'GET_PROJECT_NOTIFICATIONS';
export const UPDATE_PROJECT_NOTIFICATIONS = 'UPDATE_PROJECT_NOTIFICATIONS';
export const DELETE_PROJECT_NOTIFICATIONS = 'DELETE_PROJECT_NOTIFICATIONS';
export const ADD_USER_TO_PROJECT_NOTIFICATIONS =
  'ADD_USER_TO_PROJECT_NOTIFICATIONS';
export const UPDATE_USER_PROJECT_NOTIFICATIONS =
  'REMOVE_USER_FROM_PROJECT_NOTIFICATIONS';
export const REMOVE_USER_FROM_PROJECT_NOTIFICATIONS =
  'REMOVE_USER_FROM_PROJECT_NOTIFICATIONS';

export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const ADD_USER_TO_PROJECT = 'ADD_USER_TO_PROJECT';
export const REMOVE_USER_FROM_PROJECT = 'REMOVE_USER_FROM_PROJECT';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_RESOURCE = 'UPLOAD_RESOURCE';

export const SET_ALERT = 'SET_ALERT';
export const DELETE_ALERT = 'DELETE_ALERT';
