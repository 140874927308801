import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVersionLog } from '../../actions/projectActions';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

class Collapse extends Component {
  state = {
    toggledCollapse: false,
    logItems: [],
    log: {},
    isLoading: false,
    isLoadingContent: false,
    showAll: false,
  };

  toggleCollapse = async () => {
    if (this.state.toggledCollapse === false) {
      this.setState({
        isLoadingContent: true,
      });

      await this.props.getVersionLog(
        this.props.project.project_id,
        this.props.items.info_id,
      );

      this.setState({
        log: this.props.log,
      });
    }

    this.setState({
      toggledCollapse: !this.state.toggledCollapse,
      isLoadingContent: false,
    });
  };

  setShowAll = () => {
    this.setState({
      showAll: !this.state.showAll,
    });
  };

  async componentDidMount() {
    await this.setState({
      logItems: _.entries(this.props.items).filter((p) =>
        p[0].includes('_file'),
      ),
      isLoading: false,
    });
    if (
      this.props.expanded.submission_label === this.props.items.submission_label
    ) {
      this.toggleCollapse();
    }
  }

  render() {
    const { submission_label, info_id } = this.props.items;
    const { showAll, isLoadingContent } = this.state;
    return (
      <div className="collapse-component">
        <div
          className="collapse-header d-flex"
          onClick={() => this.toggleCollapse()}
        >
          <i
            className={
              this.state.toggledCollapse
                ? 'ion-chevron-up small'
                : 'ion-chevron-down small'
            }
          ></i>
          <h3>
            {submission_label}{' '}
            <small className="faded">
              {info_id.includes('test:')
                ? 'Test Version'
                : info_id.includes('new:')
                ? 'New Version'
                : 'New Update'}
            </small>
          </h3>
        </div>

        <div
          className={this.state.toggledCollapse ? 'collapse-body' : 'hidden'}
        >
          {isLoadingContent ? (
            <div className="full-loading">
              <div className="spinner"></div>
              <h3>Loading Activity</h3>
            </div>
          ) : this.state.log.activities &&
            this.state.log.activities.length > 0 ? (
            <React.Fragment>
              <div className="table-row d-flex table-header">
                <div>Activity</div>
                <div>Action/Value</div>
                <div>User</div>
                <div>Date</div>
              </div>

              {this.state.log.activities.map((l, index) => (
                <div className="table-row d-flex" key={index}>
                  <div>{l.activity}</div>
                  <div
                    className={showAll ? 'show-all table-value' : 'table-value'}
                  >
                    <p
                      title={l.value}
                      className={
                        showAll ? 'mb-0 show-all' : 'show-snippet mb-0'
                      }
                    >
                      <small>{l.activity !== 'New Note' && l.action}</small>
                      {l.activity === 'New Note' ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: l.value,
                          }}
                        />
                      ) : (
                        l.value
                      )}
                    </p>
                    <button
                      className={
                        l.activity === 'New Note'
                          ? l.value.length > 62
                            ? 'btn-secondary'
                            : 'hidden'
                          : 'hidden'
                      }
                      onClick={() => this.setShowAll()}
                    >
                      {showAll ? 'Collapse' : 'Expand'}
                    </button>
                  </div>
                  <div>
                    {l.user && (
                      <React.Fragment>
                        <Link to={`/user/${l.user.toLowerCase()}`}>
                          <p className="mb-0">
                            {l.user.split('@')[0]}{' '}
                            <small>@{l.user.split('@')[1]}</small>
                          </p>
                        </Link>
                      </React.Fragment>
                    )}
                  </div>
                  <div>
                    {moment(l.update_time).format('MM/DD/YY')}{' '}
                    <small>{moment(l.update_time).format('hh:mmA')}</small>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ) : (
            <div className="table-row d-flex animated fadeIn delay-half">
              <div>No Activity</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  log: state.project.log,
});

Collapse.propTypes = {
  getVersionLog: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getVersionLog,
})(Collapse);
