import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateProject } from '../../actions/projectActions';
import TextInputGroup from '../layout/TextInputGroup';
import { configTransporter } from '../../helpers/Helpers';
import _ from 'lodash';

class TransporterSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transporterForm: {},
      isLoaded: true,
      loadingText: '',
      fields: [],
    };
  }
  componentDidMount() {
    const { project } = this.props;
    console.log('project in componentdidmount', project);
    let projectType = project.project_type;
    const transporterForm = {
      ...configTransporter().transporterForm,
      fields: configTransporter().transporterForm.fields.filter(
        (t) => t.os === projectType || t.os === 'all',
      ),
    };
    const initialFields = transporterForm.fields;

    initialFields.forEach((f) => {
      if (project[f.name]) {
        f.value = project[f.name];
      }
    });
    setTimeout(() => {
      console.log('initalFields2', initialFields);
    }, 1000);

    this.setState({
      fields: initialFields,
    });
  }

  onChange = (e, selectedItem) => {
    this.setState({
      fields: this.state.fields.map((f) => {
        if (f.name === selectedItem.name) {
          f.value = e.target.value;
          return f;
        }
        return f;
      }),
    });
  };

  saveAppInfo = () => {
    let object = {};
    let arr = this.state.fields.map((f) => {
      return { [f.name]: f.value };
    });
    object = arr.reduce(function (obj, item) {
      obj[Object.keys(item)[0]] = Object.values(item)[0];
      return obj;
    }, {});

    this.props.updateProject(object, this.props.project);
    this.setState({
      isLoaded: false,
      loadingText: 'Updating Project',
    });

    setTimeout(() => {
      this.setState({
        isLoaded: true,
        loadingText: '',
      });
    }, 1000);
  };

  render() {
    const { isLoaded, loadingText, fields } = this.state;
    return (
      <React.Fragment>
        {!isLoaded && (
          <div className="full-loading">
            <div className="spinner"></div>
            <h3>{loadingText}</h3>
          </div>
        )}
        <div className="d-flex card-header">
          <h2>App Info</h2>
          <button onClick={() => this.saveAppInfo()} className="white">
            Save
          </button>
        </div>
        <div className="d-flex full-width">
          {fields.map((f, index) => (
            <TextInputGroup
              key={index}
              label={f.label}
              name={f.name}
              placeholder={f.placeholder || `Enter ${f.type}`}
              type={f.type}
              value={f.value || ''}
              onChange={(e) => this.onChange(e, f)}
              help={f.help}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

TransporterSettings.propTypes = {
  updateProject: PropTypes.func.isRequired,
};

export default connect(null, {
  updateProject,
})(TransporterSettings);
