import React, { Component } from 'react';
import { configShowProject } from 'helpers/Helpers';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: JSON.parse(localStorage.getItem('current_user')),
      userEmail: localStorage.getItem('userEmail'),
      toggledNav: false,
      toggledForms: false,
      currentRoute: '',
      searchAndFilteredArray: [],
      search: '',
      selectedPage: {},
      userType: JSON.parse(localStorage.getItem('current_user')).userType,
      userNavOptions: [
        /* {
          title: 'About',
          link: '/about',
        }, */
      ],
      formNavOptions: configShowProject().formNavOptions,
    };
  }

  onChange = (event) => {
    this.setState({ search: event.target.value.substr(0, 20) });
    setTimeout(() => {
      this.setState({
        searchAndFilteredArray: this.props.items.filter(
          (t) =>
            t.project_name
              .toLowerCase()
              .search(this.state.search.toLowerCase()) !== -1,
        ),
      });
    }, 50);
  };

  toggleNav = () => {
    this.setState({
      toggledNav: !this.state.toggledNav,
      toggledForms: false,
    });
  };

  toggleForms = () => {
    this.setState({
      toggledForms: !this.state.toggledForms,
      toggledNav: false,
    });
  };

  goTo = (link) => {
    this.props.history.push(link);
    this.setState({ toggledNav: false, search: '' });

    if (this.props.history.location.pathname.includes('/project/')) {
      window.location.reload();
    }
  };

  downloadFile = (link) => {
    window.open(link, 'Download');
  };

  clearSearch = () => {
    this.setState({
      search: '',
    });
  };

  logout = () => {
    this.props.history.push('/logout');
  };

  componentDidMount() {
    this.setState({
      searchAndFilteredArray: this.props.items,
    });
  }

  render() {
    let {
      userNavOptions,
      formNavOptions,
      toggledNav,
      toggledForms,
      search,
      searchAndFilteredArray,
      userEmail,
      userType,
    } = this.state;
    return (
      <React.Fragment>
        <nav className="header-container">
          <div className="header">
            <div className="left" onClick={() => this.goTo('/')}>
              <img src="/alps-logo.png" className="logo" alt="logo" />
            </div>

            <div className="right">
              <div className="active search-btn">
                <i className="ion-search hide-mobile" />
                <input
                  className="search hide-mobile"
                  value={search}
                  onChange={this.onChange.bind(this)}
                  placeholder="Search Projects..."
                />
                {this.state.search.length > 0 && (
                  <i className="ion-ios-close" onClick={this.clearSearch} />
                )}
              </div>
              {userType === 'admin' && (
                <React.Fragment>
                  <div
                    className="nav-btn"
                    onClick={() => this.goTo('/projects')}
                  >
                    Projects
                  </div>
                  <div className="nav-btn" onClick={() => this.goTo('/users')}>
                    Users
                  </div>
                </React.Fragment>
              )}
              <div
                className="nav-btn"
                onMouseEnter={() =>
                  this.setState({
                    toggledForms: true,
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    toggledForms: false,
                  })
                }
              >
                <div onClick={() => this.toggleForms()}>
                  Download Forms
                  <i className="ion-arrow-down-b small" />
                </div>
                {toggledForms && (
                  <div className="nav-drawer">
                    {formNavOptions.map((f) => (
                      <div
                        key={f.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => this.downloadFile(f.link)}
                        className="nav-option"
                      >
                        {f.image && (
                          <React.Fragment>
                            <img src={f.image} alt={f.title} />
                            {f.title}
                          </React.Fragment>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                className="nav-btn"
                onMouseEnter={() =>
                  this.setState({
                    toggledNav: true,
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    toggledNav: false,
                  })
                }
              >
                <div
                  className="avatar-container"
                  onClick={() => this.toggleNav()}
                  title={userEmail}
                  /* style={{ backgroundImage: `url(${currentUser.avatar})` }} */
                >
                  {userEmail.substring(0, 1)}
                </div>
                <i className="ion-arrow-down-b small" />
                {toggledNav && (
                  <div className="nav-drawer avatar">
                    {userNavOptions.map((n) => (
                      <div
                        className="nav-option"
                        key={n.title}
                        onClick={() => this.goTo(n.link)}
                      >
                        {n.title}
                      </div>
                    ))}
                    <div className="nav-option" onClick={() => this.logout()}>
                      Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
        {search.length > 0 && (
          <div className={`search-results ${userType}`}>
            <table className="list-item">
              <tbody>
                {searchAndFilteredArray.map((i) => (
                  <tr
                    key={i.project_id}
                    onClick={() => this.goTo(`/project/${i.project_id}`)}
                  >
                    <td>
                      <img
                        src={`/${i.project_type}.png`}
                        alt={i.project_type}
                      />
                      {i.project_name}{' '}
                      <i
                        className={
                          i.status === 'ARCHIVED'
                            ? 'ion-record danger small'
                            : 'ion-record success small'
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Header;
