import { combineReducers } from 'redux';
import userReducer from './userReducer';
import projectReducer from './projectReducer';
import uploadReducer from './uploadReducer';
import alertReducer from './alertReducer';

export default combineReducers({
  project: projectReducer,
  user: userReducer,
  file: uploadReducer,
  alert: alertReducer,
});
