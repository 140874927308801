import React from 'react';

const Footer = () => {
  return (
    <div className="footer animated fadeIn delay-2s">
      <h3>Contact Info</h3>
      <div className="d-flex">
        <a href="mailto:WBAppsDistribution@warnerbros.com">
          <i className="ion-ios-email" />
          WB Apps Distribution
          <div className="small">WBAppsDistribution@warnerbros.com</div>
        </a>
        <a href="mailto:kendell.burton@warnerbros.com">
          <i className="ion-ios-email" />
          Kendell Burton
          <div className="small">kendell.burton@warnerbros.com</div>
        </a>
      </div>
    </div>
  );
};

export default Footer;
