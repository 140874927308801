import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

class TableSort extends Component {
  state = {
    headers: this.props.headers,
    items: [],
    sortBy: this.props.sortBy,
    sortAsc: this.props.sortAsc,
  };

  goTo = (id) => {
    this.props.goTo(id);
  };

  setSort = (sort) => {
    if (sort.value === this.state.sortBy.value) {
      this.setState({
        items: _.reverse(this.state.items),
        sortAsc: false,
      });
    } else {
      this.setState({
        items: _.orderBy(this.state.items, sort.value),
        sortAsc: true,
      });
    }
    this.setState({
      sortBy: sort,
    });
  };

  async componentDidMount() {
    await this.setState({
      items: this.props.items.map((i) => {
        return {
          ...i,
          project_name: i.project_name,
          last_submission_status: i.last_submission_status.toLowerCase(),
        };
      }),
    });
    //Hack to get sorting working
    await this.setSort({ label: 'Current Version Status' });
    await this.setSort(this.props.sortBy);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({
        items: this.props.items,
      });
      this.setSort(this.props.sortBy);
    }
  }

  formatText = (text) => {
    if (text === 'items_required') {
      return 'PREPARING FOR SUBMISSION';
    } else {
      return text.replace(/_/g, ' ');
    }
  };

  render() {
    const { headers, items, sortBy } = this.state;
    return (
      <React.Fragment>
        <div className="table-row table-header">
          {headers.map((t) => (
            <div
              className="table-cell"
              key={t.value}
              onClick={() => this.setSort(t)}
            >
              {t.label}
              <img
                alt={t.label}
                src="./SortButton.svg"
                className={t.value === sortBy.value ? '' : 'hidden'}
              />
            </div>
          ))}
        </div>
        {items.map((p) => (
          <div
            className="table-row"
            key={p.project_id}
            onClick={() => this.goTo(p.project_id)}
          >
            <div className="table-cell">
              <img src={`/${p.project_type}.png`} />
              <span> {p.project_name}</span>
            </div>

            <div className="table-cell">
              <small className="status">
                {this.formatText(p.last_submission_status)}
              </small>
              <small className="faded">{p.last_submission_label}</small>
            </div>
            <div className="table-cell">
              {p.update_time ? (
                <div>
                  {moment(p.update_time).format('MM/DD/YY')}
                  <small className="faded">
                    {moment(p.update_time).format('h:mma')}
                  </small>
                </div>
              ) : (
                <div>
                  {moment(p.create_time).format('MM/DD/YY')}
                  <small className="faded">
                    {moment(p.create_time).format('h:mma')}
                  </small>
                </div>
              )}
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default TableSort;
