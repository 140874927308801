import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getProjects,
  getProject,
  deleteProject,
  updateProject,
  getProjectNotifications,
  updateProjectVersionStatus,
  createProjectVersion,
  updateProjectVersionNotes,
  addUserToProjectNotifications,
  removeUserFromProjectNotifications,
  updateAssetStatus,
  updateUserProjectNotifications,
} from '../../actions/projectActions';
import { getCurrentUser } from '../../actions/userActions';
import Header from '../layout/Header';
import Modal from '../layout/Modal';
import Collapse from '../layout/Collapse';
import Dropdown from '../layout/Dropdown';
import TableRow from './TableRow';
import NotificationRow from './NotificationRow';
import TransporterSettings from './TransporterSettings';
import {
  userNotificationsModalContent,
  userNotificationsModalContentEmpty,
  addNewAppVersionModalContent,
  addNewTestVersionModalContent,
  removeUserFromNotificationsModalContent,
  archiveProjectModalContent,
  restoreProjectModalContent,
  editProjectModalContent,
  deleteProjectModalContent,
  goToS3BucketModalContent,
  editAppVersionModalContent,
  editTestVersionModalContent,
} from '../../helpers/ModalContent';
import { getDownloadUrl, getAssetFileNames } from '../../support/alpsApi';
import _ from 'lodash';
import queryString from 'query-string';
import { configShowProject } from 'helpers/Helpers';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import axios from 'axios';
import { DefaultEditor } from 'react-simple-wysiwyg';
import TransporterTool from './TransporterTool';

class ShowProject extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.transporterRef = React.createRef();
    this.state = {
      //project: {},
      file: {},
      projectList: [],
      reversedProjectVersions: null,
      isLoaded: false,
      loadingText: 'Loading Project',
      saveNoteLoading: false,
      downloadAssetsLoading: false,
      selectedVersion: {},
      selectedVersionInputFields: [],
      tabs: [],
      selectedTab: {},
      dropdownItems: [],
      toggledDropdown: false,
      resource_path: '',
      modalContent: {},
      notifications: {},
      updateComponent: false,
      viewType: JSON.parse(localStorage.getItem('current_user')).userType,
      userType: JSON.parse(localStorage.getItem('current_user')).userType,
      assetDropdownOptions: configShowProject().assetDropdownOptions,
      overflowOptionsAdmin: configShowProject().overflowOptionsAdmin,
      overflowOptionsUser: configShowProject().overflowOptionsUser,
      overflowOptionsVersion: configShowProject().overflowOptionsVersion,
      superButtonOptions: configShowProject().superButtonOptions,
      developerResources: {},
      html: '',
      activeRow: 'none',
      toggledTransporter: false,
      availableTabs: [
        {
          os: 'ios',
          tabs: [
            { label: 'App Store', value: 1 },
            { label: 'TestFlight', value: 2 },
            { label: 'Activity', value: 3 },
          ],
        },
        {
          os: 'android',
          tabs: [
            { label: 'Google Play', value: 1 },
            { label: 'Test Builds', value: 2 },
            { label: 'Activity', value: 3 },
          ],
        },
      ],
    };
    this.onChangeNote = this.onChangeNote.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    this.setState({ html: e.target.value });
  };

  getProject = async () => {
    this.setState({
      activeRow: 'none',
    });

    await this.props.getProject(this.props.match.params.id);
    const { project, projects } = this.props;

    if ((project && project.versions.length) === 0) {
      this._isMounted = false;
      this.goTo();
    } else {
      if (project.status === 'DELETED' || project.versions.length === 0) {
        this._isMounted = false;
        this.goTo();
      } else {
        this.setState({
          resource_path: projects.Items.filter(
            (p) => p.project_id === this.props.match.params.id,
          )[0].resource_path,
          file: this.props.file,
        });
        if (project.status === 'ARCHIVED') {
          this.setState({
            assetDropdownOptions: configShowProject().assetDropdownOptions1,
            overflowOptionsAdmin: configShowProject().overflowOptionsAdmin1,
          });
        } else {
          this.setState({
            assetDropdownOptions: configShowProject().assetDropdownOptions,
            overflowOptionsAdmin: configShowProject().overflowOptionsAdmin,
          });
        }

        await this.createTabs(project);

        if (this.state.userType === 'admin') {
          await this.props.getProjectNotifications(this.props.match.params.id);
          this.setState({
            notifications: this.props.notifications,
          });
        }
      }
      this.setState({
        isLoaded: true,
        loadingText: '',
        developerResources: project.resource
          ? { ...project.resource, name: 'Developer Resources' }
          : { name: 'Developer Resources' },
      });
    }
  };

  setSelectedVersionInputFields = (tab, selectedVersionObject) => {
    if (_.isEmpty(this.state.selectedVersion)) {
      if (tab.value === 1) {
        if (
          this.props.project.versions.filter((p) => p.info_id.includes('new:'))
            .length > 0
        ) {
          let tempList1 = [];
          tempList1 = this.props.project.versions
            .filter(
              (p) =>
                p.info_id.includes('new:') || p.info_id.includes('update:'),
            )
            .pop();

          this.setState({
            selectedVersion: tempList1,
            selectedVersionInputFields: Object.entries(tempList1),
          });
        }
      }
      if (tab.value === 2) {
        if (
          this.props.project.versions.filter((p) => p.info_id.includes('test:'))
            .length > 0
        ) {
          let tempList2 = [];
          tempList2 = this.props.project.versions
            .filter((p) => p.info_id.includes('test:'))
            .pop();
          this.setState({
            selectedVersion: tempList2,
            selectedVersionInputFields: Object.entries(tempList2),
          });
        } else {
          this.setState({
            selectedVersion: {},
            selectedVersionInputFields: [],
          });
        }
      }
    } else {
      this.setState({
        selectedVersion: selectedVersionObject,
        selectedVersionInputFields: selectedVersionObject.assets,
      });
    }
  };

  goTo = () => {
    this.props.history.push('/projects');
  };

  downloadResource = () => {
    getDownloadUrl(this.state.resource_path, this.props.match.params.id).then(
      (resp) => {
        window.open(resp);
      },
    );
  };

  grabAssetFileNames = () => {
    let typesWithAssets = [];
    let foldersWithFiles = [];

    //Generate OS Label for Zip Filename
    let OS = '';
    if (this.props.project.project_type === 'ios') {
      OS = 'iOS';
    }
    if (this.props.project.project_type === 'android') {
      OS = 'Google Play';
    }

    //New Array with Only Uploaded Assets
    if (this.state.selectedVersion.assets) {
      typesWithAssets = this.state.selectedVersion.assets.filter(
        (a) => a.asset,
      );

      foldersWithFiles = typesWithAssets.map((u) => u.name);
    }
  };

  downloadAssets = () => {
    this.setState({
      isLoaded: false,
      loadingText: 'Downloading Assets from s3',
    });

    //Generate OS Label for Zip Filename
    let OS = '';
    if (this.props.project.project_type === 'ios') {
      OS = 'iOS';
    }
    if (this.props.project.project_type === 'android') {
      OS = 'Google Play';
    }

    //API get array of file names
    let fileNamesToDownload = [];
    getAssetFileNames(
      this.props.match.params.id,
      this.state.selectedVersion.submission_label,
      this.state.selectedVersion.info_id,
      this.props.project.project_type,
    ).then((response) => {
      if (response.length === 0) {
        this.setState({
          loadingText: 'No Assets Available.',
        });
        setTimeout(() => {
          this.setState({
            isLoaded: true,
          });
        }, 2000);
      } else {
        fileNamesToDownload = response.map((f) => {
          return {
            fileName: `${f.split('/').reverse()[1]}-${
              f.split('/').reverse()[0]
            }`,
            path: f,
          };
        });

        if (this.state.selectedVersion) {
          let zipFileName = `${this.props.project.project_name}-${OS}-${this.state.selectedVersion.submission_label}`;

          let zippedFile = [];

          fileNamesToDownload.forEach((u) =>
            getDownloadUrl(u.path, this.props.match.params.id).then((resp) => {
              zippedFile.push({ name: u.fileName, res: resp });
            }),
          );

          let isReady = () => {
            if (zippedFile.length === fileNamesToDownload.length) {
              var zip = new JSZip();
              var count = 0;
              zippedFile.forEach((file) => {
                console.log(
                  'this.state.selectedVersion',
                  this.state.selectedVersion,
                );
                axios
                  .get(file.res, {
                    responseType: 'blob',
                  })
                  .then((response) => {
                    zip.file(file.name, response.data, {
                      binary: true,
                    });

                    ++count;

                    if (count === zippedFile.length) {
                      zip
                        .generateAsync({
                          type: 'blob',
                        })
                        .then(function (content) {
                          FileSaver.saveAs(content, zipFileName + '.zip');
                        });
                      setTimeout(() => {
                        this.setState({
                          isLoaded: true,
                          loadingText: '',
                        });
                      }, 1000);
                    }
                  })
                  .catch((error) => {
                    alert(error);
                  });
              });
            } else {
              setTimeout(() => {
                isReady();
              }, 3000);
            }
          };
          isReady();
        }
      }
    });
  };

  setSelectedVersion = async (selectedTab, selectedVersionObject) => {
    //Set Active Version, & Set the URL params to reflect version
    await this.setState({
      toggledDropdown: false,
      selectedVersion: selectedVersionObject,
    });
    let urlParams = this.getUrlParams();
    if (urlParams.tab === '1') {
      this.props.history.push({
        search: `?tab=${urlParams.tab}&appVersion=${selectedVersionObject.submission_label}&testVersion=${urlParams.testVersion}`,
      });
    } else {
      this.props.history.push({
        search: `?tab=${urlParams.tab}&appVersion=${urlParams.appVersion}&testVersion=${selectedVersionObject.submission_label}`,
      });
    }
    this.setSelectedVersionInputFields(selectedTab, selectedVersionObject);
  };

  setSelectedTab = async (tab) => {
    //Set active tab & Set the URL params to reflect tab then get data
    await this.setState({
      selectedTab: tab ? tab : this.state.tabs[0],
    });
    let urlParams = this.getUrlParams();

    this.props.history.push({
      search: `?tab=${tab.value}&appVersion=${urlParams.appVersion}&testVersion=${urlParams.testVersion}`,
    });

    if (tab.value === 1) {
      if (urlParams.appVersion !== 'undefined') {
        this.setState({
          selectedVersion: this.props.project.versions.filter(
            (p) => p.submission_label === urlParams.appVersion,
          )[0],
        });
      } else {
        this.setSelectedTabOnInit();
      }
    }
    if (tab.value === 2) {
      if (urlParams.testVersion !== 'undefined') {
        this.setState({
          selectedVersion: this.props.project.versions.filter(
            (p) => p.submission_label === urlParams.testVersion,
          )[0],
        });
      } else {
        let tempArray = this.props.project.versions.filter((v) =>
          v.info_id.includes('test:'),
        );
        if (tempArray.length > 0) {
          this.setState({ selectedVersion: tempArray[0] });
        } else {
          this.setState({
            selectedVersion: {},
          });
        }
      }
    }

    if (tab.value === 3) {
      //console.log('tab is 3');
      //this.getProject();
    }

    this.setSelectedVersionInputFields(
      this.state.selectedTab,
      this.state.selectedVersion,
    );
  };

  getUrlParams = () => {
    return queryString.parse(this.props.location.search);
  };

  setSelectedTabOnInit = async (tab) => {
    //On screen load, check if URL params, if not, build it, set tab and get data for tab + version
    const values = this.getUrlParams();
    const appVersionArray = await this.props.project.versions.filter(
      (p) => !p.info_id.includes('test:'),
    );
    const testVersionArray = await this.props.project.versions.filter((p) =>
      p.info_id.includes('test:'),
    );

    let appVersionForUrl = appVersionArray.reverse()[0].submission_label;
    let testVersionForUrl =
      testVersionArray.length > 0
        ? testVersionArray.reverse()[0].submission_label
        : 'undefined';

    if (
      values.tab &&
      values.tab.length > 0 &&
      values.appVersion !== 'undefined' &&
      values.appVersion &&
      values.appVersion.length > 0
    ) {
      if (values.tab === '1') {
        //Check if URL Params has an appVersion, if so, use URL param value and set appVersionForUrl
        if (
          appVersionArray.length > 0 &&
          appVersionArray.filter(
            (a) => a.submission_label === values.appVersion,
          ).length > 0
        ) {
          appVersionForUrl = appVersionArray.filter(
            (a) => a.submission_label === values.appVersion,
          )[0].submission_label;
        }

        this.setState({
          selectedVersion:
            appVersionArray.length > 0
              ? appVersionArray.filter(
                  (a) => a.submission_label === values.appVersion,
                ).length > 0
                ? appVersionArray.filter(
                    (a) => a.submission_label === values.appVersion,
                  )[0]
                : appVersionArray[0]
              : {},
          selectedTab: this.state.tabs[0],
        });

        this.props.history.push({
          search:
            '?tab=1&appVersion=' +
            appVersionForUrl +
            '&testVersion=' +
            testVersionForUrl,
        });
      } else if (values.tab === '2') {
        this.setState({
          selectedVersion:
            testVersionArray.length > 0
              ? testVersionArray.filter(
                  (a) => a.submission_label === values.testVersion,
                ).length > 0
                ? testVersionArray.filter(
                    (a) => a.submission_label === values.testVersion,
                  )[0]
                : testVersionArray[0]
              : {},
          selectedTab: this.state.tabs[1],
        });

        this.props.history.push({
          search:
            '?tab=2&appVersion=' +
            appVersionForUrl +
            '&testVersion=' +
            testVersionForUrl,
        });
      } else if (values.tab === '3') {
        this.setState({
          selectedVersion: appVersionArray[0],
          selectedTab: this.state.tabs[2],
        });
      } else if (values.tab === '4') {
        this.setState({
          selectedVersion: appVersionArray[0],
          selectedTab: this.state.tabs[3],
        });
      } else {
        this.setState({ selectedTab: this.state.tabs[0] });
        this.setState({
          selectedVersion: appVersionArray[0] ? appVersionArray[0] : {},
        });
      }
    } else {
      //Create URL Params
      this.props.history.push({
        search:
          '?tab=1&appVersion=' +
          appVersionForUrl +
          '&testVersion=' +
          testVersionForUrl,
      });

      //Set Selected Version
      this.setState({
        selectedVersion: appVersionArray.reverse()[0],
        selectedTab: this.state.tabs[0],
      });
    }

    await this.setSelectedVersionInputFields(
      this.state.selectedTab,
      this.state.selectedVersion,
    );
    this.setSelectedTab(this.state.selectedTab);
  };

  createTabs = async (project) => {
    this.setState({
      tabs: this.state.availableTabs
        .filter((a) => a.os === project.project_type)
        .map((p) => p.tabs)[0],
    });

    if (this.state.viewType === 'admin') {
      this.setState({
        tabs: [...this.state.tabs, { label: 'Settings', value: 4 }],
      });
    }

    await this.setSelectedTabOnInit();

    try {
      this.setState({
        dropdownItems: [
          this.props.project.versions.filter(
            (p) => p.info_id.includes('new:') || p.info_id.includes('update:'),
          ),
          this.props.project.versions.filter((p) =>
            p.info_id.includes('test:'),
          ),
        ],
      });
    } catch (error) {}
  };

  setModalContent = (selectedTab, type, content) => {
    if (selectedTab.value === 1) {
      this.setState({
        modalContent: addNewAppVersionModalContent(),
      });
    }
    if (selectedTab.value === 2) {
      this.setState({
        modalContent: addNewTestVersionModalContent(),
      });
    }
    if (type === 'Edit Version') {
      if (this.state.selectedTab.value === 1) {
        this.setState({
          modalContent: editAppVersionModalContent(this.state.selectedVersion),
        });
      } else {
        this.setState({
          modalContent: editTestVersionModalContent(this.state.selectedVersion),
        });
      }
    }
    if (type === 'Remove User from Notifications') {
      this.setState({
        modalContent: removeUserFromNotificationsModalContent(
          content,
          this.props.project,
        ),
      });
    }
    if (type === 'Archive Project') {
      this.setState({
        modalContent: archiveProjectModalContent(this.props.project),
      });
    }
    if (type === 'Restore Project') {
      this.setState({
        modalContent: restoreProjectModalContent(this.props.project),
      });
    }
    if (type === 'Edit Project') {
      this.setState({
        modalContent: editProjectModalContent(this.props.project),
      });
    }
    if (type === 'Go To S3 Bucket') {
      this.setState({
        modalContent: goToS3BucketModalContent(
          this.props.project,
          this.state.selectedVersion,
        ),
      });
    }
    if (type === 'Delete Project') {
      this.setState({
        modalContent: deleteProjectModalContent(this.props.project),
      });
    }
    if (type === 'User Notifications') {
      if (content) {
        this.setState({
          modalContent: userNotificationsModalContent(content),
        });
      } else {
        this.setState({
          modalContent: userNotificationsModalContentEmpty(),
        });
      }
    }
  };

  handleEditTransporterDetails = () => {
    this.setSelectedTab({ label: 'Settings', value: 4 });
    this.setState({
      toggledTransporter: false,
    });
    setTimeout(() => {
      this.transporterRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 500);
  };

  handleModalAction = (action, object) => {
    if (action === 'Cancel') {
      this.setState({
        modalContent: {},
        toggledTransporter: false,
      });
    }
    if (action === 'Update Project') {
      this.props.updateProject(object, this.props.project);
      this.setState({
        isLoaded: false,
        loadingText: 'Updating Project',
        updateComponent: true,
        modalContent: {},
      });
    }
    if (action === 'Delete') {
      this.props.deleteProject(this.props.project.project_id);
      this.setState({
        modalContent: {},
        isLoaded: false,
        loadingText: 'Deleting Project',
        updateComponent: true,
      });
      setTimeout(() => {
        this.props.history.push('/projects');
      }, 1000);
    }
    if (action === 'Archive' || action === 'Restore') {
      let objectData = 'ACTIVE';
      if (this.props.project.status === 'ACTIVE') {
        objectData = {
          fields: [{ name: 'status', value: 'ARCHIVED' }],
        };
      } else {
        objectData = {
          fields: [{ name: 'status', value: 'ACTIVE' }],
        };
      }
      this.props.updateProject(objectData, this.props.project);

      this.setState({
        modalContent: {},
        isLoaded: false,
        loadingText: 'Updating Project',
        updateComponent: true,
      });
    }
    if (action === 'Create App Version') {
      const urlParams = this.getUrlParams();
      this.props.createProjectVersion(object, this.props.project, 'update');
      this.setState({
        modalContent: {},
        isLoaded: false,
        loadingText: 'Creating Version',
        updateComponent: true,
        selectedVersion: {},
      });
      this.props.history.push({
        search: `?tab=${urlParams.tab}&appVersion=${object.fields[0].value}&testVersion=${urlParams.testVersion}`,
      });
    }
    if (action === 'Update App Version') {
      const urlParams = this.getUrlParams();
      this.props.updateProjectVersionStatus(
        this.props.project,
        this.state.selectedVersion,
        { submission_label: object.fields[0].value },
      );
      this.setState({
        modalContent: {},
        isLoaded: false,
        loadingText: 'Updating Version',
        updateComponent: true,
        selectedVersion: {},
      });
      this.props.history.push({
        search: `?tab=${urlParams.tab}&appVersion=${object.fields[0].value}&testVersion=${urlParams.testVersion}`,
      });
    }
    if (action === 'Create Test Version') {
      const urlParams = this.getUrlParams();
      this.props.createProjectVersion(object, this.props.project, 'test');
      this.setState({
        modalContent: {},
        isLoaded: false,
        loadingText: 'Creating Version',
        updateComponent: true,
        selectedVersion: {},
      });
      this.props.history.push({
        search: `?tab=${urlParams.tab}&appVersion=${urlParams.appVersion}&testVersion=${object.fields[0].value}%20-%20${object.fields[1].value}`,
      });
    }
    if (action === 'Update Test Version') {
      const urlParams = this.getUrlParams();
      this.props.updateProjectVersionStatus(
        this.props.project,
        this.state.selectedVersion,
        {
          submission_type: 'test',
          submission_label: `${object.fields[0].value} - ${object.fields[1].value}`,
        },
      );
      this.setState({
        modalContent: {},
        isLoaded: false,
        loadingText: 'Updating Version',
        updateComponent: true,
        selectedVersion: {},
      });
      this.props.history.push({
        search: `?tab=${urlParams.tab}&appVersion=${urlParams.appVersion}&testVersion=${object.fields[0].value} - ${object.fields[1].value}`,
      });
    }
    if (action === 'Add User Notification') {
      this.props.addUserToProjectNotifications(this.props.project.project_id, {
        [object.fields[0].name]: object.fields[0].value,
        [object.fields[1].name]: object.fields[1].value,
        [object.fields[2].name]: object.fields[2].value,
        [object.fields[3].name]: object.fields[3].value,
      });
      this.setState({
        isLoaded: false,
        loadingText: 'Adding User to Notifications',
        modalContent: {},
        updateComponent: true,
      });
    }
    if (action === 'Update Notifications') {
      this.props.updateUserProjectNotifications(
        this.props.project.project_id,
        object.contentObject.email,
        {
          email: object.fields[0].value,
          first_name: object.fields[1].value,
          last_name: object.fields[2].value,
          subscription_type: object.fields[3].value,
        },
      );
      this.setState({
        isLoaded: false,
        loadingText: 'Updating User Notifications',
        modalContent: {},
        updateComponent: true,
      });
    }
    if (action === 'Remove User') {
      this.props.removeUserFromProjectNotifications(
        this.props.project.project_id,
        object.data,
      );
      this.setState({
        isLoaded: false,
        loadingText: 'Removing User from Notifications',
        modalContent: {},
        updateComponent: true,
      });
    }
  };

  toggleTransporterTool = () => {
    this.setState({ toggledTransporter: !this.state.toggledTransporter });
  };

  handleDropdownAction = (action) => {
    if (action.value === 'Edit Project') {
      this.setModalContent({}, 'Edit Project');
    }
    if (action.value === 'Edit Version') {
      this.setModalContent({}, 'Edit Version');
    }
    if (action.value === 'Download Assets') {
      this.downloadAssets();
    }
    if (action.value === 'Transporter Tool') {
      this.toggleTransporterTool();
    }
    if (action.value === 'Archive Project') {
      this.setModalContent({}, 'Archive Project');
    }
    if (action.value === 'Restore Project') {
      this.setModalContent({}, 'Restore Project');
    }
    if (action.value === 'Delete Project') {
      this.setModalContent({}, 'Delete Project');
    }
    if (action.value === 'Go To S3 Bucket') {
      this.setModalContent({}, 'Go To S3 Bucket');
    }
    if (action.value === 'View As User') {
      this.props.history.push({
        search: `?tab=1&appVersion=${this.state.selectedVersion.submission_label}`,
      });
      this.setState({
        selectedTab: this.state.tabs[0],
        viewType: 'user',
      });
      this.getProject();

      setTimeout(() => {
        this.createTabs(this.props.project);
      }, 50);
    }
    if (action.value === 'View As Admin') {
      this.setState({
        viewType: 'admin',
      });
      setTimeout(() => {
        this.createTabs(this.props.project);
      }, 50);
    }
    if (action.action === 'supersubmit') {
      this.props.updateProjectVersionStatus(
        this.props.project,
        this.state.selectedVersion,
        { status: action.value },
      );
      this.setState({
        isLoaded: false,
        loadingText: 'Updating Version Status',
        updateComponent: true,
      });
    }
  };

  handleSuperSubmit = () => {
    this.props.updateProjectVersionStatus(
      this.props.project,
      this.state.selectedVersion,
      { status: 'PENDING' },
    );

    this.setState({ isLoaded: false, updateComponent: true });
  };

  handleEditContent = (content) => {
    this.setModalContent({ value: 3 }, 'User Notifications', content);
  };

  onChangeNote = (e) => {
    this.setState({
      newNote: e.target.value,
    });
  };

  saveNote = () => {
    this.setState({
      saveNoteLoading: true,
    });
    this.props.updateProjectVersionNotes(
      this.props.project,
      this.state.selectedVersion,
      this.state.html,
    );
    this.setState({
      html: '',
    });
  };

  handleRemoveUser = (content) => {
    this.setModalContent(
      { value: 3 },
      'Remove User from Notifications',
      content,
    );
  };

  addUserToNotifications = () => {
    this.setModalContent({ value: 3 }, 'User Notifications');
  };

  handleUpdateComponentFromRow = () => {
    this.setState({ updateComponent: true, activeRow: 'none' });
  };

  handleSetActiveRow = async (data) => {
    if (data === 'none') {
      this.setState({ activeRow: 'none' });
    } else {
      this.setState({ activeRow: data });
    }
  };

  getDropdownTitle = () => {
    try {
      return this.state.superButtonOptions.filter(
        (s) => s.value === this.state.selectedVersion.status,
      )[0].label;
    } catch (error) {}
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.props.getProjects();
    await this.getProject();
    await this.props.getCurrentUser();
    console.log('selectedVersion', this.state.selectedVersion);
    let lowerCasedProjectList = await this.props.projects.Items.map((i) => {
      return {
        project_name_lowercased: i.project_name.toLowerCase(),
        project_name: i.project_name,
        project_type: i.project_type,
        project_id: i.project_id,
      };
    });
    await this.setState({
      currentUser: this.props.currentUser,
      isLoaded: true,
      projectList: _.orderBy(lowerCasedProjectList, 'project_name_lowercased'),
      reversedProjectVersions: this.props.project.versions.reverse(),
    });
    this.grabAssetFileNames();
  }

  async componentDidUpdate(prevProps, prevState) {
    try {
      if (prevState.updateComponent) {
        this.setState({ updateComponent: false });
        if (prevState.updateComponent !== this.state.updateComponent) {
          this.props.getProjects();
          this.getProject();
        }
      }

      if (this.props.alert && !_.isEmpty(this.props.alert)) {
        this.getProject();
        this.props.deleteAlert();
      }

      if (prevProps.file !== this.props.file) {
        this.setState({
          activeRow: 'none',
        });
        setTimeout(() => {
          this.setState({
            activeRow: 'none',
          });
        }, 5000);
      }

      if (prevProps.notes !== this.props.notes) {
        this.setState({
          html: '',
          saveNoteLoading: false,
        });

        setTimeout(() => {
          this.setState({
            html: '',
            saveNoteLoading: false,
          });
        }, 5000);
      }
    } catch (error) {}
  }

  render() {
    const {
      projectList,
      dropdownItems,
      toggledDropdown,
      selectedVersion,
      selectedVersionInputFields,
      isLoaded,
      loadingText,
      saveNoteLoading,
      tabs,
      selectedTab,
      resource_path,
      modalContent,
      userType,
      notifications,
      viewType,
      overflowOptionsAdmin,
      overflowOptionsUser,
      overflowOptionsVersion,
      superButtonOptions,
      developerResources,
      html,
      activeRow,
      toggledTransporter,
      reversedProjectVersions,
      downloadAssetsLoading,
    } = this.state;

    const { project } = this.props;

    return (
      <React.Fragment>
        <Header history={this.props.history} items={projectList}></Header>
        <div
          className={
            userType === 'admin'
              ? `admin container show-project tab${selectedTab.value}`
              : `container show-project tab${selectedTab.value}`
          }
        >
          {isLoaded && project ? (
            <React.Fragment>
              {!_.isEmpty(modalContent) && (
                <Modal
                  content={modalContent}
                  modalAction={this.handleModalAction}
                ></Modal>
              )}
              {toggledTransporter && (
                <TransporterTool
                  project={project}
                  selectedVersion={selectedVersion}
                  modalAction={this.handleModalAction}
                  editTransporterDetails={this.handleEditTransporterDetails}
                ></TransporterTool>
              )}

              <h1 className="d-flex">
                <span>
                  <span onClick={() => this.goTo()}>
                    <i className="ion-chevron-left small" />{' '}
                    <span>
                      <img
                        src={`/${project.project_type}.png`}
                        alt={project.project_type}
                      />
                    </span>
                    {project.project_name}
                  </span>

                  {/* <i
                    className={
                      project.status === 'ACTIVE'
                        ? 'ion-record success small'
                        : 'ion-record danger small'
                    }
                    title={project.status === 'ACTIVE' ? 'Active' : 'Archived'}
                  /> */}
                </span>
                <div className="d-flex">
                  <span
                    className={resource_path ? 'small' : 'hidden'}
                    onClick={() => this.downloadResource()}
                  >
                    <i className="ion-arrow-down-a" />
                    DEV RESOURCES
                  </span>
                  {userType === 'admin' && viewType === 'admin' && (
                    <Dropdown
                      type="icon"
                      title="ion-android-more-vertical"
                      options={overflowOptionsAdmin}
                      dropdownAction={this.handleDropdownAction}
                    ></Dropdown>
                  )}
                  {userType === 'admin' && viewType === 'user' && (
                    <Dropdown
                      type="icon"
                      title="ion-android-more-vertical"
                      options={overflowOptionsUser}
                      dropdownAction={this.handleDropdownAction}
                    ></Dropdown>
                  )}
                </div>
              </h1>
              <div className="tab-group">
                {tabs.map((t) => (
                  <div
                    key={t.value}
                    className={selectedTab.value === t.value ? 'active' : ''}
                    onClick={() => this.setSelectedTab(t)}
                  >
                    {t.label}
                  </div>
                ))}
              </div>

              <div className={selectedTab.value === 4 ? '' : 'hidden'}>
                <div className="card">
                  <div className="d-flex card-header">
                    <h2>Developer Resources</h2>
                  </div>
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>Upload File</th>
                          <th title="File currently on the server.">
                            Last Uploaded File
                          </th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <TableRow
                          inputAttributes={developerResources}
                          project={project}
                          allowedFileFormats={{
                            'Developer Resources': 'application/zip',
                          }}
                          assetRequirements={project.AssetRequirements}
                          rowAction={this.handleRowAction}
                          updateComponent={this.handleUpdateComponentFromRow}
                          setActiveRow={this.handleSetActiveRow}
                          activeRow={activeRow}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card">
                  <div
                    className={
                      !_.isEmpty(notifications) &&
                      notifications.Items.length > 0
                        ? 'd-flex card-header'
                        : 'hidden'
                    }
                  >
                    <h2>User Notifications</h2>
                    <button
                      onClick={() => this.addUserToNotifications()}
                      className="white mb-1"
                    >
                      Add User
                    </button>
                  </div>
                  <div className="card-body">
                    {!_.isEmpty(notifications) &&
                    notifications.Items.length > 0 ? (
                      <table className="settings-table">
                        <thead>
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {notifications.Items.map((i, index) => (
                          <NotificationRow
                            key={index}
                            content={i}
                            handleEditContent={this.handleEditContent}
                            handleRemoveUser={this.handleRemoveUser}
                          />
                        ))}
                      </table>
                    ) : (
                      <div className="placeholder">
                        <i className="ion-android-notifications faded"></i>
                        <h3>Send notifications to team members</h3>
                        <p className="faded">
                          Add people you'd like ALPS to send email notifications
                          to when statuses or versions change.
                        </p>
                        <button onClick={() => this.addUserToNotifications()}>
                          Add New User
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="card" ref={this.transporterRef}>
                  <TransporterSettings project={project}></TransporterSettings>
                </div>
              </div>

              <div className={selectedTab.value === 3 ? '' : 'hidden'}>
                {selectedTab.value === 3 && (
                  <div className="card">
                    <div className="d-flex card-header">
                      <h2>Activity</h2>
                    </div>
                    {reversedProjectVersions &&
                      reversedProjectVersions.map((p, index) => (
                        <div key={index}>
                          <Collapse
                            expanded={project.versions[0]}
                            items={p}
                            project={project}
                          ></Collapse>
                        </div>
                      ))}
                  </div>
                )}
              </div>

              {selectedVersionInputFields.length > 0 ? (
                <React.Fragment>
                  <div
                    className={
                      selectedTab.value !== 3 && selectedTab.value !== 4
                        ? 'card'
                        : 'hidden'
                    }
                  >
                    <div className="d-flex card-header">
                      <div className="d-flex">
                        <div
                          className="dropdown-container"
                          onMouseEnter={() =>
                            this.setState({
                              toggledDropdown: true,
                            })
                          }
                          onMouseLeave={() =>
                            this.setState({
                              toggledDropdown: false,
                            })
                          }
                        >
                          <button>
                            {selectedVersion.submission_label}{' '}
                            <i className="ion-arrow-down-b small" />
                          </button>
                          <div
                            className={toggledDropdown ? 'dropdown' : 'hidden'}
                          >
                            {selectedTab.value === 1 ? (
                              <React.Fragment>
                                {_.reverse(
                                  dropdownItems[0].map((d, index) => (
                                    <span
                                      key={index}
                                      onClick={() =>
                                        this.setSelectedVersion(selectedTab, d)
                                      }
                                    >
                                      {d.submission_label}
                                    </span>
                                  )),
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {_.reverse(
                                  dropdownItems[1].map((d, index) => (
                                    <span
                                      key={index}
                                      onClick={() =>
                                        this.setSelectedVersion(selectedTab, d)
                                      }
                                    >
                                      {d.submission_label}
                                    </span>
                                  )),
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                        <button
                          className="super-submit ml"
                          onClick={() => this.setModalContent(selectedTab)}
                        >
                          New
                          {selectedTab.value === 1 ? ' Update' : ' Version'}
                        </button>
                        {/* {viewType === 'admin' && (
                          <React.Fragment>
                            {!_.isEmpty(this.state.selectedVersion) &&
                            this.state.selectedVersion.assets.filter(
                              (a) => a.asset,
                            ).length > 0 ? (
                              <React.Fragment>
                                <button
                                  title="Downloads all the files/assets (below) of this version."
                                  className={
                                    downloadAssetsLoading
                                      ? 'hidden'
                                      : 'super-submit btn-transparent ml'
                                  }
                                  onClick={() => this.downloadAssets()}
                                >
                                  <i className="ion-arrow-down-a"></i> Assets
                                </button>
                                <button
                                  className={
                                    downloadAssetsLoading
                                      ? 'spinner-btn'
                                      : 'hidden'
                                  }
                                >
                                  <div className="spinner"></div>
                                </button>
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </React.Fragment>
                        )} */}
                      </div>
                      {viewType === 'admin' && (
                        <div className="d-flex">
                          <div className="uppercase">
                            <Dropdown
                              title={this.getDropdownTitle()}
                              options={superButtonOptions}
                              dropdownAction={this.handleDropdownAction}
                            ></Dropdown>
                          </div>

                          <Dropdown
                            type="icon"
                            title="ion-android-more-vertical"
                            options={overflowOptionsVersion}
                            dropdownAction={this.handleDropdownAction}
                          ></Dropdown>
                        </div>
                      )}
                      {viewType === 'user' && (
                        <div className="container-super-submit">
                          <button
                            title={selectedVersion.status !== 'READY_TO_SUBMIT' ? 'Please submit all required} assets and forms.' : 'Submit'}
                            className="super-submit btn-success"
                            disabled={
                              selectedVersion.status !== 'READY_TO_SUBMIT'
                            }
                            onClick={() => this.handleSuperSubmit()}
                          >
                            {selectedVersion.status !== 'SUBMITTED' &&
                            selectedVersion.status !== 'PENDING' ? (
                              'Submit App'
                            ) : (
                              <span>
                                {
                                  superButtonOptions.filter(
                                    (s) => s.value === selectedVersion.status,
                                  )[0].label
                                }
                              </span>
                            )}
                          </button>
                          <small className="faded">
                            {selectedVersion.status === 'ITEMS_REQUIRED' && (
                              <em>Missing Assets</em>
                            )}
                            {selectedVersion.status === 'SUBMITTED' && (
                              <em>
                                {/* {moment(selectedVersion.update_time).format(
                                  'MM/DD/YYYY hh:mma',
                                )} */}
                                ALPS Submitted App
                              </em>
                            )}
                            {selectedVersion.status === 'READY_TO_SUBMIT' && (
                              <em>Ready to Submit</em>
                            )}
                            {selectedVersion.status === 'PENDING' && (
                              <em>Waiting for ALPS</em>
                            )}
                            {selectedVersion.status === 'REJECTED' && (
                              <em>Version Rejected by ALPS</em>
                            )}
                          </small>
                        </div>
                      )}
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            Asset{' '}
                            <span className="danger">
                              (*<small>Required</small>)
                            </span>
                          </th>
                          <th></th>
                          <th title="File currently on the server.">
                            Last Uploaded File
                          </th>
                          <th title="Current status of file submission on Alps System.">
                            Alps Status
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedVersionInputFields &&
                          selectedVersionInputFields.map((s, index) => (
                            <TableRow
                              project={project}
                              selectedVersion={selectedVersion}
                              inputAttributes={s}
                              allowedFileFormats={project.AllowedFileFormats}
                              assetRequirements={project.AssetRequirements}
                              key={index}
                              viewType={viewType}
                              rowAction={this.handleRowAction}
                              updateComponent={
                                this.handleUpdateComponentFromRow
                              }
                              setActiveRow={this.handleSetActiveRow}
                              activeRow={activeRow}
                            />
                          ))}
                      </tbody>
                    </table>
                    <br />
                    <div>
                      <div className="d-flex">
                        <h3 className="mb-0">Notes</h3>
                        <button
                          className={saveNoteLoading ? 'hidden' : 'small mb-1'}
                          onClick={() => this.saveNote()}
                          disabled={html === ''}
                        >
                          Add Note
                        </button>
                        <button
                          className={saveNoteLoading ? 'spinner-btn' : 'hidden'}
                        >
                          <div className="spinner"></div>
                        </button>
                      </div>

                      <div className="wysiwyg-container">
                        <DefaultEditor value={html} onChange={this.onChange} />
                      </div>
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {selectedTab.value === 2 && (
                    <div className="card">
                      <div className="placeholder">
                        <i className="ion-images faded"></i>
                        <h3>Add a new version to get started</h3>

                        <button
                          onClick={() => this.setModalContent(selectedTab)}
                        >
                          New Version
                        </button>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <div className="full-loading">
              <div className="spinner"></div>
              <h3>{loadingText}</h3>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.project.projects,
  project: state.project.project,
  currentUser: state.user.currentUser,
  notifications: state.project.notifications,
  notes: state.project.notes,
  file: state.file.file,
});

ShowProject.propTypes = {
  getProjects: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateProjectVersionStatus: PropTypes.func.isRequired,
  createProjectVersion: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  getProjectNotifications: PropTypes.func.isRequired,
  updateProjectVersionNotes: PropTypes.func.isRequired,
  notes: PropTypes.object.isRequired,
  addUserToProjectNotifications: PropTypes.func.isRequired,
  removeUserFromProjectNotifications: PropTypes.func.isRequired,
  updateAssetStatus: PropTypes.func.isRequired,
  updateUserProjectNotifications: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getProjects,
  updateProject,
  deleteProject,
  getProject,
  updateProjectVersionStatus,
  createProjectVersion,
  getCurrentUser,
  getProjectNotifications,
  updateProjectVersionNotes,
  addUserToProjectNotifications,
  removeUserFromProjectNotifications,
  updateAssetStatus,
  updateUserProjectNotifications,
})(ShowProject);
