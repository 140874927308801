import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProjects } from '../../actions/projectActions';

class Placeholder extends Component {
  componentDidMount() {
    this.props.getProjects();

    setTimeout(() => {
      if (this.props.projects.Items.length > 0) {
        this.props.history.push('/');
      }
    }, 2000);
  }

  render() {
    return (
      <div className="landing">
        <div className="container animated placeholder-container fadeInUp delay-1s">
          <a href="/">
            <div className="container-img">
              <img src="/alps-logo.png" alt="logo" />
            </div>
          </a>

          <div className="text-container">
            <h1 className="mb-0">No Projects</h1>
            <p>There are no projects assigned to you.</p>
            <div>Please contact:</div>
            <div>
              <a
                href="mailto:WBAppsDistribution@warnerbros.com"
                className="white"
              >
                WBAppsDistribution@warnerbros.com
              </a>
            </div>
          </div>

          <a href="/logout" className="button btn-secondary">
            Logout
          </a>
        </div>
      </div>
    );
  }
}

Placeholder.propTypes = {
  getProjects: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  projects: state.project.projects,
});

export default connect(mapStateToProps, {
  getProjects,
})(Placeholder);
