import React, { Component } from 'react';
import { configTransporter } from '../../helpers/Helpers';
import TextInputGroup from '../layout/TextInputGroup';
import _ from 'lodash';
import moment from 'moment';

class TransporterTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transporterForm: {},
      fileValidated: false,
      timeValidated: null,
      timeSubmitted: null,
      isLoaded: true,
      loadingLabel: '',
      submitted: false,
      hasFile: false,
      fields: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleModalAction = (action, data, contentObject) => {
    if (action === 'Cancel') {
      this.props.modalAction(action);
    }
    if (action === 'Validate') {
      this.setState({
        fileValidated: true,
        isLoaded: false,
        loadingLabel: 'Validating File',
      });

      setTimeout(() => {
        this.setState({
          isLoaded: true,
          loadingLabel: '',
          timeValidated: new Date(),
        });
      }, 3000);
    }
    if (action === 'Submit App') {
      this.setState({
        isLoaded: false,
        loadingLabel: 'Submitting App',
      });

      setTimeout(() => {
        this.setState({
          isLoaded: true,
          loadingLabel: '',
          submitted: true,
          timeSubmitted: new Date(),
        });
      }, 3000);
    }
  };

  handleChange(e) {
    this.setState({
      fields: this.state.fields.map((f) => {
        if (f.name === e.target.name) {
          return {
            ...f,
            value: e.target.value,
          };
        } else {
          return f;
        }
      }),
    });
  }

  disableButton = (c) => {
    if (c.label === 'Validate') {
      if (
        (this.state.transporterForm.fields &&
          this.state.fields.filter((f) => f.value.length === 0).length !== 0) ||
        !this.state.hasFile
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (c.label !== 'Validate' && this.state.fileValidated === false) {
      //console.log('disabled', c.label);
      return true;
    } else {
      return false;
    }
  };

  handleFileAdded = (e) => {
    this.setState({
      hasFile: true,
    });
  };

  componentDidMount() {
    const { project } = this.props;
    let projectType = project.project_type;
    const transporterFormForOs = {
      ...configTransporter().transporterForm,
      fields: configTransporter().transporterForm.fields.filter(
        (t) => t.os === projectType || t.os === 'all',
      ),
    };
    
    const initialFields = transporterFormForOs.fields;

    initialFields.forEach((f) => {
      if (project[f.name]) {
        f.value = project[f.name];
      }
    });

    this.setState({
      fields: initialFields,
      transporterForm: configTransporter().transporterForm,
    });
  }

  render() {
    const {
      isLoaded,
      loadingLabel,
      submitted,
      fileValidated,
      timeValidated,
      timeSubmitted,
      transporterForm,
      fields,
    } = this.state;
    return (
      <div className="modal transporter-container">
        <div className="modal-content animated fadeInUp faster">
          <div className="modal-header">
            <h2>Transporter Tool</h2>
            <i
              className="ion-android-close large"
              onClick={() => this.props.modalAction('Cancel')}
            />
          </div>
          <div className="modal-body projects">
            <div className={isLoaded ? '' : 'hidden'}>
              <div className="form-group">
                <label htmlFor="selected versio">
                  Build Details{' '}
                  {fileValidated ? (
                    <small className={submitted ? 'hidden' : 'primary'}>
                      Last Validated{' '}
                      {moment(timeValidated).format('MM/DD/YY h:mmA')}
                    </small>
                  ) : (
                    <small className="danger">Not Validated</small>
                  )}
                </label>
                Project: {this.props.project.project_name}, Version:{' '}
                {this.props.selectedVersion.submission_label}
              </div>
              {submitted ? (
                <div className="center">
                  <br />
                  <h2>
                    <i className="ion-ios-checkmark success"></i> Submitted to
                    App Store
                  </h2>
                  <p>
                    File submitted on{' '}
                    {moment(timeSubmitted).format('MM/DD/YY h:mmA')}. You'll
                    receive a confirmation email with the submission details.
                  </p>
                </div>
              ) : (
                <React.Fragment>
                  {fields.map((f, index) => (
                    <div className="form-group">
                      <label htmlFor={f.label}>
                        {f.label}{' '}
                        <a
                          className="faded small"
                          onClick={() => this.props.editTransporterDetails()}
                        >
                          Edit in App Info
                        </a>
                      </label>
                      {f.value}
                    </div>
                  ))}

                  <div className="input-group">
                    <label htmlFor="upload">Zip File To Submit</label>
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={this.handleFileAdded.bind(this)}
                    />
                  </div>
                  <br />
                </React.Fragment>
              )}
            </div>

            <div className={isLoaded ? 'hidden' : 'full-loading'}>
              <div className="spinner"></div>
              {loadingLabel}
            </div>
          </div>

          <div className="modal-footer d-flex">
            {!_.isEmpty(transporterForm) &&
              !submitted &&
              transporterForm.commands.map((c) => (
                <button
                  key={c.label}
                  className={c.type}
                  disabled={this.disableButton(c)}
                  onClick={() => this.handleModalAction(c.label)}
                >
                  {c.label}
                </button>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default TransporterTool;
