import {
  GET_PROJECTS,
  GET_PROJECT,
  GET_VERSION_LOG,
  UPDATE_PROJECT,
  CREATE_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT_VERSION_STATUS,
  UPDATE_PROJECT_ASSET_STATUS,
  CREATE_PROJECT_VERSION,
  GET_PROJECT_NOTIFICATIONS,
  UPDATE_PROJECT_VERSION_NOTES,
  ADD_USER_TO_PROJECT_NOTIFICATIONS,
  UPDATE_USER_PROJECT_NOTIFICATIONS,
  REMOVE_USER_FROM_PROJECT_NOTIFICATIONS,
} from '../actions/types';

const initialState = {
  projects: {},
  project: {},
  notifications: {},
  notes: {},
  log: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case DELETE_PROJECT:
      return {
        ...state,
        project: {},
      };
    case GET_PROJECT:
      return {
        ...state,
        project: action.payload,
      };
    case GET_VERSION_LOG:
      return {
        ...state,
        log: action.payload,
      };
    case ADD_USER_TO_PROJECT_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.Items.push(
          action.payload.Attributes,
        ),
      };

    case UPDATE_USER_PROJECT_NOTIFICATIONS:
      if (action.payload.Attributes) {
        let notificationsObject = {
          ...state.notifications,
          Items: action.payload.Items,
        };

        setTimeout(() => {
          return {
            ...state,
            notifications: notificationsObject,
          };
        }, 100);
      } else {
        let notificationsObject = {
          ...state.notifications,
          Items: action.payload.Items,
        };
        setTimeout(() => {
          return {
            ...state,
            notifications: notificationsObject,
          };
        }, 100);
      }

    case REMOVE_USER_FROM_PROJECT_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };

    case UPDATE_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          project_name: action.payload.Attributes.project_name,
        },
      };

    case CREATE_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          Items: [...state.projects.Items, action.payload],
        },
        project: action.payload,
      };
    case CREATE_PROJECT_VERSION:
      const addedProjectVersion = action.payload;
      const newProjectObject = state.project;
      newProjectObject.versions.push(addedProjectVersion);
      return {
        ...state,
        project: newProjectObject,
        projects: {
          ...state.projects,
          Items: [...state.projects.Items, newProjectObject],
        },
      };
    case UPDATE_PROJECT_VERSION_STATUS:
      return {
        ...state,
        project: {
          ...state.project,
          versions: [
            ...state.project.versions,
            state.project.versions.filter(
              p => p.info_id === action.payload.info_id,
            )[0],
          ],
        },
      };
    case UPDATE_PROJECT_ASSET_STATUS:
      return {
        ...state,
        project: {
          ...state.project,
          versions: [
            state.project.versions.filter(
              p => p.info_id === action.payload.versions[0].info_id,
            )[0],
            ...state.project.versions,
          ],
        },
      };
    case UPDATE_PROJECT_VERSION_NOTES:
      return {
        ...state,
        notes: action.payload,
      };

    case GET_PROJECT_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
}
