import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProjects } from '../../actions/projectActions';
import {
  getCurrentUser,
  getUser,
  addUserToProject,
  removeUserFromProject,
} from '../../actions/userActions';
import Header from '../layout/Header';
import Modal from '../layout/Modal';
import {
  addUserToProjectModalContent,
  removeUserFromProjectModalContent,
} from '../../helpers/ModalContent';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';

class ShowUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userProjects: [],
      isLoaded: false,
      updateComponent: false,
      viewType: JSON.parse(localStorage.getItem('current_user')).userType,
      userType: JSON.parse(localStorage.getItem('current_user')).userType,
    };
  }

  getUser = async () => {
    //('get project tiggered');
    await this.props.getUser(this.props.match.params.id);

    let userProjectsFlatArray = this.props.user.Items.map(
      (i) => i.project_id,
    ).slice(0, -1);

    this.setState({
      user: this.props.user.Items.pop(),
      userProjects: this.props.projects.Items.filter((i) =>
        userProjectsFlatArray.includes(i.project_id),
      ),
      updateComponent: false,
      isLoaded: true,
    });
  };

  goTo = () => {
    this.props.history.push('/users');
  };

  setModalContent = async (type, content) => {
    if (type === 'Add User To Project') {
      let filteredProjectIdArray = this.state.userProjects.map(
        (u) => u.project_id,
      );

      const projectsNotAssignedToUser = content.filter(
        (c) => !filteredProjectIdArray.includes(c.project_id),
      );

      this.setState({
        modalContent: addUserToProjectModalContent(projectsNotAssignedToUser),
      });
    }
    if (type === 'Remove User From Project') {
      this.setState({
        modalContent: removeUserFromProjectModalContent(
          content,
          this.state.user.user_id,
        ),
      });
    }
  };

  handleModalAction = (action, object) => {
    if (action === 'Cancel') {
      this.setState({
        modalContent: {},
      });
    }
    if (action === 'Add User To Project') {
      this.props.addUserToProject(
        this.state.user.user_id,
        object.project_id,
        object.project_name,
      );
      this.setState({
        modalContent: {},
        updateComponent: true,
        isLoaded: false,
      });
    }
    if (action === 'Remove') {
      this.props.removeUserFromProject(
        this.state.user.user_id,
        object.project_id,
        object.project_name,
      );
      this.setState({
        modalContent: {},
        updateComponent: true,
        isLoaded: false,
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async componentDidMount() {
    await this.props.getProjects();
    await this.getUser();
    await this.props.getCurrentUser();
    await this.setState({
      currentUser: this.props.currentUser,
      projectList: this.props.projects.Items,
    });
  }

  componentDidUpdate(prevState) {
    try {
      if (prevState.updateComponent) {
        this.setState({ updateComponent: false });
        this.getUser();
      }
    } catch (error) {}
  }

  render() {
    const { user, userProjects, projectList, modalContent } = this.state;

    return (
      <React.Fragment>
        {/* <div
          className="blurred-background"
          style={{
            backgroundImage: `url(/AlpsMountains.jpg)`,
          }}
        ></div> */}
        <Header history={this.props.history} items={projectList}></Header>

        <div className="container projects">
          <React.Fragment>
            {!_.isEmpty(modalContent) && (
              <Modal
                content={modalContent}
                modalAction={this.handleModalAction}
              ></Modal>
            )}
            {!_.isEmpty(user) ? (
              <React.Fragment>
                <h1 className="d-flex">
                  <span onClick={() => this.goTo()}>
                    <i className="ion-chevron-left small" /> {user.first_name}{' '}
                    {user.last_name}
                    <small className="faded smaller"> {user.user_id}</small>
                  </span>

                  {/* <button onClick={() => this.setModalContent()}>
                    <i className='ion-plus-round' /> User
                  </button> */}
                </h1>
                {userProjects.length > 0 ? (
                  <div className="table card">
                    <div className="d-flex card-header">
                      <h2>Assigned Projects</h2>
                      <button
                        className="super-submit"
                        onClick={() =>
                          this.setModalContent(
                            'Add User To Project',
                            this.props.projects.Items,
                          )
                        }
                      >
                        <i className="ion-plus-round" />
                        &nbsp;Project
                      </button>
                    </div>

                    <div className="table-row table-header">
                      <div className="table-cell">Name</div>
                      <div className="table-cell">Last Updated</div>
                      <div className="table-cell">Actions</div>
                    </div>
                    {userProjects.map((u) => (
                      <div
                        className="table-row settings-table"
                        key={u.project_id}
                      >
                        <div className="table-cell">
                          <Link to={`/project/${u.project_id}`}>
                            {u.project_type === 'ios' && (
                              <img src="/Apple.png" alt="iOS" />
                            )}
                            {u.project_type === 'android' && (
                              <img src="/GooglePlay.png" alt="Google Play" />
                            )}
                            <span> {u.project_name}</span>
                          </Link>
                        </div>
                        <div className="table-cell">
                          {moment(u.update_time).format('MM/DD/YYYY')}
                          <small className="faded">
                            {moment(u.update_time).format('hh:mma')}
                          </small>
                        </div>
                        <div className="table-cell">
                          <button
                            className="btn-secondary"
                            onClick={() =>
                              this.setModalContent(
                                'Remove User From Project',
                                u,
                              )
                            }
                          >
                            <i className="ion-trash-b"></i>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="card">
                    <div className="placeholder">
                      <i className="ion-images faded"></i>
                      <h3>Add a project that this user can access.</h3>

                      <button
                        onClick={() =>
                          this.setModalContent(
                            'Add User To Project',
                            this.props.projects.Items,
                          )
                        }
                      >
                        Add Project
                      </button>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className="full-loading">
                <div className="spinner"></div>
                <h3>Loading User</h3>
              </div>
            )}
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  currentUser: state.user.currentUser,
  projects: state.project.projects,
});

ShowUser.propTypes = {
  getUser: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  addUserToProject: PropTypes.func.isRequired,
  removeUserFromProject: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getUser,
  getCurrentUser,
  getProjects,
  addUserToProject,
  removeUserFromProject,
})(ShowUser);
